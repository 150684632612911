import * as ContactsApiTypes from '@onereach/types-contacts-api';
import { find, findIndex } from 'lodash';
import { defineStore } from 'pinia';

import { ContactsApiV2 } from '@/api';
import { ContactBooksOrderModes, MigrationsStatus } from '@/constants';
import { InitTrigger } from '@/types';

export const useContactsBooksStore = defineStore('contactBooksV2', {
  state: () => ({
    migrationsStatus: MigrationsStatus.UNDEFINED,
    total: undefined as number | undefined,
    items: [] as ContactsApiTypes.ContactBookResponseDto[],
    triggers: [] as InitTrigger[],
    sharedBookMetaInfo: [] as ContactsApiTypes.SharedBookResponseDto[],
  }),
  getters: {
    getBookById: (state) => {
      return (id: string) => {
        return find<ContactsApiTypes.ContactBookResponseDto>(state.items, { id });
      };
    },
    getBookByName: (state) => {
      return (name: string) => {
        return find<ContactsApiTypes.ContactBookResponseDto>(state.items, { label: name });
      };
    },
    /**
     * TODO: implement
     */
    getAccessPermissionsByBookName: () => {
      return () => {
        throw new Error('Not implemented');
      };
    },
    sharedBookMeta({ sharedBookMetaInfo }): ContactsApiTypes.SharedBookResponseDto[] {
      return sharedBookMetaInfo;
    },
  },
  actions: {
    async getContactBooks(
      {
        orderMode = ContactBooksOrderModes.ASC,
        orderField = 'label' as 'label' | 'created_at' | 'updated_at',
        take = undefined as number | undefined,
        skip = undefined as number | undefined,
        q = undefined as string | undefined,
      } = {}): Promise<void> {
      const resp = await ContactsApiV2.contactBookApi.listContactBook({
        orderProperty: orderField,
        orderDirection: orderMode,
        size: take,
        from: skip,
        q,
      });
      this.total = resp.total;
      this.items = resp.items;
    },
    async addContactBook(payload: ContactsApiTypes.CreateContactBookDto): Promise<string> {
      const data = await ContactsApiV2.contactBookApi.createContactBook(payload);
      this._updateContactBook(data);
      return data.id;
    },
    async deleteContactBook(id: string): Promise<void> {
      await ContactsApiV2.contactBookApi.deleteContactBook(id);
    },
    async deleteBookContacts(bookId: string): Promise<void> {
      // eslint-disable-next-line max-len
      const { totalBatches: initialButchesCount } = await ContactsApiV2.contactApi.initDeleteBookContactsBulk(bookId, false, 400, true);

      let batchesLeft = initialButchesCount;

      // Repeat the process until all batches are processed
      while (batchesLeft > 0) {
        // eslint-disable-next-line max-len
        const { totalBatches: currentLeftButches } = await ContactsApiV2.contactApi.initDeleteBookContactsBulk(bookId, false, 400, true,);
        batchesLeft = currentLeftButches;
      }
    },
    _updateContactBook(data: ContactsApiTypes.ContactBookResponseDto): void {
      const index = findIndex(this.items, { id: data.id });
      if (index > -1) {
        this.items[index] = data;
      } else {
        this.items.push(data);
      }
    },
    async getContactBook(id: string): Promise<void> {
      try {
        const data = await ContactsApiV2.contactBookApi.getContactBook(id);
        this._updateContactBook(data);
      } catch (e) {
        console.warn(e);
      }
    },
    async updateContactBook(id: string, dto: ContactsApiTypes.UpdateContactBookDto): Promise<void> {
      const data = await ContactsApiV2.contactBookApi.updateContactBook(id, dto);
      this._updateContactBook(data);
    },
    async shareCrossAccountContactBook(id: string, dto: ContactsApiTypes.ShareContactBookParamsDto): Promise<void> {
      await ContactsApiV2.contactBookApi.shareContactBook(id, dto);
      await this.getSharedBookMeta(id);
    },
    async approveSharing(id: string): Promise<void> {
      await ContactsApiV2.contactBookApi.approveSharedBook(id);
      await this.getContactBook(id);
    },
    async stopSharingContactBook(id: string, data: ContactsApiTypes.ShareContactBookParamsDto): Promise<void> {
      await ContactsApiV2.contactBookApi.rejectShareBook(id, data);
      await this.getSharedBookMeta(id);
    },

    async restoreShareBook(id: string, shareMetaId: string): Promise<void> {
      await ContactsApiV2.contactBookApi.restoreShareContactBook(id, shareMetaId);
      await this.getSharedBookMeta(id);
    },

    // eslint-disable-next-line max-len
    async updateShareBookContactPermission(id: string, data: ContactsApiTypes.UpdateSharedBookContactPermissionDto): Promise<void> {
      await ContactsApiV2.sharedBookContactPermissionApi.updateSharedBookContactPermission(id, data);
    },

    async getSharedBookMeta(id: string): Promise<void> {
      this.sharedBookMetaInfo = await ContactsApiV2.contactBookApi.getSharedBookMeta(id);
    },
  },
});

export default useContactsBooksStore;
