<template>
  <div
    class="contacts-table-container max-w-full h-full overflow-auto bg-background dark:bg-background-dark shadow-card dark:shadow-card-dark"
  >
    <div
      class="contacts-table table w-auto min-w-full bg-background dark:bg-background-dark"
      data-test="contacts-table"
    >
      <div
        v-if="isShowingCheckboxesColumnShadow"
        class="w-[80px] h-full absolute left-none z-[1] shadow-contextual dark:shadow-contextual-dark"
      />
      <div class="contacts-table-head sticky top-none z-[2]">
        <div class="row flex w-auto clear-both head-row h-[34px] typography-caption-semibold bg-background dark:bg-background-dark theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 !border-b-1">
          <div
            ref="checkboxCellIntersectionPartRef"
            class="w-none h-full"
          />
          <div
            v-if="!isCheckboxHidden"
            class="header-cell inline-block px-xl action-select flex-none theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 sticky z-[1] left-none bg-background dark:bg-background-dark"
            :class="{ '!border-r-1': !isHeaderRowLoading }"
          >
            <div class="flex flex-row items-center w-full h-full">
              <OrCheckboxV3
                v-if="areCheckboxesVisible"
                :indeterminate="isAnySelected && !isAllSelected"
                :model-value="isAnySelected"
                :is-disabled="isEmpty"
                @update:modelValue="handleSelectAll"
              />
            </div>
          </div>
          <template v-if="!isHeaderRowLoading">
            <div
              v-for="column in fieldSchemasToShow"
              :key="`${column.label}_${column.machine_name}`"
              class="header-cell inline-block text-ellipsis text-start whitespace-nowrap theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 !border-r-1"
              data-test="header-cell"
              :style="dataCellCss[column.id]"
            >
              <contacts-table-column-header-cell
                :column-key="column.id"
                :value="column.label"
                :width="storedColumnsInfo[column.id].width"
                :can-be-resized-now="!isAnyResizing"
                :can-be-ordered="typesToOrder.includes(column.type)"
                :order-mode="columnToOrderBy === column.id ? orderMode : null"
                @widthChange="onColumnHeaderWidthChange"
                @barDragStart="onColumnHeaderWidthChangeStart"
                @barDragEnd="onColumnHeaderWidthChangeEnd"
                @order="onOrderChange(column.id, $event)"
              />
            </div>
          </template>
          <template v-else>
            <div
              v-for="key in loadingColumnsKeys"
              :key="key"
              class="header-cell inline-block pl-sm text-ellipsis text-start whitespace-nowrap border-y-0"
              :style="{width: loadingColumnsWidths[key]}"
              data-test="header-cell"
            >
              <div class="h-full flex items-center">
                <OrSkeletonRectV3
                  :width="96"
                  :height="21"
                  class="max-w-full"
                />
              </div>
            </div>
          </template>
          <div class="header-cell inline-block min-w-0 grow" />
        </div>
      </div>
      <div
        v-if="!isShowingHeaderOnly"
        class="contacts-table-body"
      >
        <template v-if="!areContactsLoading">
          <div
            v-for="contact in contacts"
            :key="contact.id"
            class="row flex w-auto clear-both contact-row cursor-pointer typography-body-2-regular theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 !border-b-1 hover:bg-primary-opacity-0-08 dark:hover:bg-primary-opacity-0-08-dark [&_.checkbox-bg]:hover:bg-primary-opacity-0-08 [&_.checkbox-bg]:dark:hover:bg-primary-opacity-0-08-dark"
            data-test="contact-row"
            :class="{
              'bg-primary-opacity-0-12 dark:bg-primary-opacity-0-12-dark': selected.includes(contact.id) && singleSelected !== contact.id,
              'bg-primary-opacity-0-16 dark:bg-primary-opacity-0-16-dark': singleSelected === contact.id,
            }"
            @click="$emit('view', contact.id)"
          >
            <div
              v-if="!isCheckboxHidden"
              class="cell inline-block select-action flex-none sticky z-[1] left-none bg-background dark:bg-background-dark"
              @click.stop
            >
              <div
                class="checkbox-bg w-full h-full px-xl"
                :class="{
                  'bg-primary-opacity-0-12 dark:bg-primary-opacity-0-12-dark': selected.includes(contact.id) && singleSelected !== contact.id,
                  'bg-primary-opacity-0-16 dark:bg-primary-opacity-0-16-dark': singleSelected === contact.id,
                }"
              >
                <div class="flex flex-row items-start w-full h-full pt-[14px]">
                  <OrCheckboxV3
                    v-if="areCheckboxesVisible"
                    :model-value="selected.includes(contact.id)"
                    @update:modelValue="handleSelect(contact.id)"
                  />
                </div>
              </div>
            </div>
            <div
              v-for="column in fieldSchemasToShow"
              :key="column.id"
              data-test="contact-cell"
              class="cell inline-block pl-sm whitespace-nowrap overflow-hidden text-ellipsis"
              :class="{ 'border-r-2 border-r-primary-hover dark:border-r-primary-hover-dark': resizingColumnKey === column.id }"
              :style="dataCellCss[column.id]"
            >
              <contacts-table-data-cell
                :type="column.type"
                :value="contact.fieldValues[column.id]?.value ?? contact[column.id]"
                class="px-sm py-sm+ overflow-hidden text-ellipsis h-full"
              />
            </div>
            <div class="cell inline-block min-w-0 grow" />
          </div>
          <div
            v-if="!isEmpty"
            class="row flex grow w-full clear-both"
          />
        </template>
        <template v-else>
          <div
            v-for="rowKey in loadingRowsKeys"
            :key="rowKey"
            class="row flex w-auto clear-both contact-row typography-body-2-regular theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 !border-b-1"
          >
            <div
              v-if="!isCheckboxHidden"
              class="cell inline-block select-action flex-none border-r-1 border-r-transparent sticky z-[1] left-none bg-background dark:bg-background-dark"
              @click.stop
            >
              <OrCheckboxV3
                v-if="areCheckboxesVisible"
                class="w-full h-full px-xl"
                is-disabled
              />
            </div>
            <div
              v-for="columnKey in loadingColumnsKeys"
              :key="columnKey"
              class="cell md:h-[45px] h-[72px] inline-block pl-sm whitespace-nowrap overflow-hidden text-ellipsis"
              :style="{width: loadingColumnsWidths[columnKey]}"
            >
              <OrSkeletonRectV3
                :width="96"
                :height="21"
                class="mt-[12px] max-w-full"
              />
            </div>
            <div class="cell inline-block min-w-0 grow" />
          </div>
          <div class="row flex grow w-full clear-both" />
        </template>
      </div>
    </div>
    <template v-if="!isLoading && isEmpty && !isShowingHeaderOnly">
      <div
        class="empty sticky left-none p-md text-center typography-body-2-regular"
        data-test="table-empty"
      >
        {{ emptyTableText }}
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { ColumnTypes, ContactBookFieldSchemaDto, ContactResponseDto } from '@onereach/types-contacts-api';
import { OrCheckboxV3, OrSkeletonRectV3 } from '@onereach/ui-components';
import { useIntersectionObserver } from '@vueuse/core';
import _ from 'lodash';
import { StoreGeneric, storeToRefs } from 'pinia';
import { defineComponent, PropType, ref, computed } from 'vue';

import { useContactsTableStoredValueV2 } from '@/composables';
import { ContactBooksOrderModes } from '@/constants';
import { useAuth } from '@/stores';
import { immutableSplice, getDefaultContactsTableStoredValueV2, getExtendedFieldsSchemas } from '@/utils';

import ContactsTableColumnHeaderCell from './ContactsTableColumnHeaderCellV2.vue';
import ContactsTableDataCell from './ContactsTableDataCellV2';


const typesToOrder = [
  ColumnTypes.string,
  ColumnTypes.md,
  ColumnTypes.integer,
  ColumnTypes.double,
  ColumnTypes.date,
  ColumnTypes['array of strings'],
  undefined,
  null,
];

export default defineComponent({
  name: 'ContactsTable',
  components: {
    OrSkeletonRectV3,
    OrCheckboxV3,
    ContactsTableDataCell,
    ContactsTableColumnHeaderCell,
  },
  props: {
    bookId: {
      type: String,
      required: false,
      default: undefined,
    },
    contacts: {
      type: Array as PropType<ContactResponseDto[]>,
      required: false,
      default: () => [],
    },
    fieldSchemas: {
      type: Array as PropType<ContactBookFieldSchemaDto[]>,
      required: false,
      default: () => [],
    },
    selected: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    singleSelected: {
      type: String,
      required: false,
      default: null,
    },
    columnToOrderBy: {
      type: String,
      required: false,
      default: 'created',
    },
    orderMode: {
      type: String as PropType<ContactBooksOrderModes>,
      required: false,
      default: ContactBooksOrderModes.DESC,
    },
    rowsLimit: {
      type: Number,
      required: false,
      default: 20,
    },
    areContactsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBookLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSearching: {
      type: Boolean,
      required: false,
      default: false,
    },
    isShowingHeaderOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCheckboxHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    addSystemFields: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['select', 'view', 'order'],
  setup(props) {
    const checkboxCellIntersectionPartRef = ref<HTMLDivElement>();
    const resizingColumnKey = ref<string| null>(null);

    const authStore = useAuth();
    const authRefs = storeToRefs(authStore as unknown as StoreGeneric);
    const accountId = authRefs.accountId.value as string;
    const contactsTableStoredValue = useContactsTableStoredValueV2(props.bookId, accountId, props.fieldSchemas);
    const isShowingCheckboxesColumnShadow = ref(false);


    const storedColumnsInfo = computed(() => {
      return contactsTableStoredValue.value.columns;
    });

    const fieldSchemasToShow = computed(() => {
      const schemas = props.addSystemFields
        ? getExtendedFieldsSchemas(props.fieldSchemas)
        : props.fieldSchemas;

      return schemas.filter(({ id }) => storedColumnsInfo.value[id as string]?.isShown);
    });

    useIntersectionObserver(
      checkboxCellIntersectionPartRef,
      ([{ intersectionRatio }]) => {
        isShowingCheckboxesColumnShadow.value = intersectionRatio === 0 && !props.isCheckboxHidden;
      },
    );

    return {
      resizingColumnKey,
      checkboxCellIntersectionPartRef,
      typesToOrder,
      contactsTableStoredValue,
      isShowingCheckboxesColumnShadow,
      storedColumnsInfo,
      fieldSchemasToShow,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.areContactsLoading || this.isBookLoading;
    },
    isHeaderRowLoading(): boolean {
      return this.isBookLoading && this.fieldSchemas.length == 0;
    },
    areCheckboxesVisible(): boolean {
      return !this.isCheckboxHidden && !this.isHeaderRowLoading;
    },
    loadingColumnsKeys(): number[] | string[] {
      return this.isHeaderRowLoading ? _.range(12) : _.map(this.fieldSchemasToShow, fs => fs.id as string);
    },
    loadingColumnsWidths(): Record<number | string, string> {
      return _.fromPairs(_.map(this.loadingColumnsKeys,
        key => [key, (this.storedColumnsInfo[key as string]?.width ?? 160) + 'px']),
      );
    },
    loadingRowsKeys(): number[] {
      return _.range(this.rowsLimit);
    },
    dataCellCss() {
      return _.mapValues(this.storedColumnsInfo, ({ width }) => ({
        width: width + 'px',
      }));
    },
    isEmpty(): boolean {
      return this.contacts.length === 0;
    },
    allItemsIds(): string[] {
      return this.contacts.map(({ id }) => id);
    },
    isAnySelected(): boolean {
      return this.selected.length > 0;
    },
    isAllSelected(): boolean {
      return !this.isEmpty && _.difference(this.allItemsIds, this.selected).length === 0;
    },
    isAnyResizing(): boolean {
      return this.resizingColumnKey !== null;
    },
    emptyTableText(): string {
      return this.isSearching ? this.$t('contacts.table.noMatchingRecords') : this.$t('contacts.table.empty');
    },
  },
  watch: {
    fieldSchemas: {
      handler(val) {
        const defaultStoredValue = getDefaultContactsTableStoredValueV2(val);
        _.defaults(this.contactsTableStoredValue.columns, defaultStoredValue.columns);
      },
      deep: true,
    },
  },
  methods: {
    handleSelectAll(): void {
      this.$emit('select', this.isAnySelected ? [] : _.union(this.allItemsIds, this.selected));
    },
    handleSelect(contactId: string): void {
      const index = this.selected.indexOf(contactId);
      if (index > -1) {
        this.$emit('select', immutableSplice(this.selected, index, 1));
      } else {
        this.$emit('select', [...this.selected, contactId]);
      }
    },
    onColumnHeaderWidthChangeStart(columnKey: string): void {
      this.resizingColumnKey = columnKey;
    },
    onColumnHeaderWidthChange(columnKey: string, newWidth: number): void {
      this.storedColumnsInfo[columnKey].width = newWidth;
    },
    onColumnHeaderWidthChangeEnd(): void {
      this.resizingColumnKey = null;
    },
    onOrderChange(columnKey: string, newOrderMode: ContactBooksOrderModes): void {
      this.$emit('order', columnKey, newOrderMode);
    },
  },
});
</script>
<style scoped lang="scss">
.cell {
  min-height: 45px;
}
</style>

