import { defineStore } from 'pinia';

import ContactsApi from '@/api/ContactsApi';
import { AddFilterDTO, Filter, UpdateFilterDTO } from '@/types';

const useFiltersStore = defineStore('filtersV1', {
  state: () => ({
    collections: {} as { [bookName: string]: Filter[]; },
    filters: {} as { [id: string]: Filter; },
  }),

  getters: {
    getFiltersByBookName: (state) => {
      return (bookName: string) => {
        return state.collections[bookName]
          ? state.collections[bookName]
          : [] as typeof state.collections[string];
      };
    },

    getFilterById: (state) => (filterId: string): Filter => state.filters[filterId],
  },

  actions: {
    async getFilters(bookName: string): Promise<void> {
      const data = await ContactsApi.getContactFiltersList({ bookName });
      this.collections[bookName] = data;
      (data as Filter[]).forEach(filter => this.filters[filter.filterId] = filter);
    },

    async getFilter(filterId: string): Promise<void> {
      this.filters[filterId] = await ContactsApi.getContactFilter({ filterId });
    },

    async addFilter({ name, bookName, conditions, matchAllConditions }: AddFilterDTO): Promise<Filter> {
      return await ContactsApi.addContactFilter({
        name,
        bookName,
        conditions,
        matchAllConditions,
      }) as Filter;
    },

    async updateFilter({ filterId, name, conditions, matchAllConditions }: UpdateFilterDTO): Promise<void> {
      await ContactsApi.updateContactFilter({
        filterId,
        name,
        conditions,
        matchAllConditions,
      });
    },

    async deleteFilter(filterId: string): Promise<void> {
      await ContactsApi.deleteContactFilter({ filterId });
      delete this.filters[filterId];
    },
  },
});

export default useFiltersStore;
