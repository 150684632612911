<template>
  <div class="header-cell-content relative w-full h-full !overflow-visible typography-caption-semibold flex items-center">
    <div class="text-content w-full h-full flex flex-row overflow-hidden">
      <or-button
        v-if="canBeOrdered"
        variant="text"
        color="inherit"
        class="w-full h-full !p-sm !min-w-0 max-w-full overflow-hidden text-ellipsis !justify-start"
        :class="buttonStyles"
        @click="changeOrder"
      >
        <span class="text self-center overflow-hidden text-ellipsis">
          {{ value }}
        </span>
        <or-icon
          v-if="orderMode"
          :icon="icon"
          color="inherit"
          class="text-outline dark:text-outline-dark"
        />
      </or-button>
      <span
        v-else
        class="text self-center p-sm max-w-full overflow-hidden text-ellipsis"
      >
        {{ value }}
      </span>
    </div>
    <div
      ref="resizeBarRef"
      class="resize-bar absolute top-none right-[-2.5px] w-[5px] h-full cursor-col-resize flex flex-row justify-center"
      :class="{ '[&>*]:hover:bg-primary-hover [&>*]:dark:hover:bg-primary-hover-dark': canBeResizedNow }"
    >
      <div
        class="w-[2px] h-full"
        :class="{'bg-primary-hover dark:bg-primary-hover-dark': isResizing}"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { OrButtonV3 as OrButton, OrIconV3 as OrIcon } from '@onereach/ui-components';
import { useDraggable } from '@vueuse/core';
import { defineComponent, PropType, ref } from 'vue';

import { ContactBooksOrderModes } from '@/constants';


export default defineComponent({
  name: 'ContactsTableColumnHeaderCell',
  components: {
    OrButton,
    OrIcon,
  },
  props: {
    columnKey: {
      type: String,
      required: false,
      default: undefined,
    },
    value: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    canBeResizedNow: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    canBeOrdered: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    orderMode: {
      type: String as PropType<ContactBooksOrderModes | null>,
      required: false,
      default: () => null,
    },
  },
  emits: ['barDragStart', 'widthChange', 'barDragEnd', 'order'],
  setup(props, { emit }) {
    const resizeBarRef = ref<HTMLElement | null>(null);

    const minWidth = 17; // 16px padding + 1px border
    let startPositionX = 0;
    let isResizing = ref(false);

    useDraggable(resizeBarRef, {
      onStart: (position, event) => {
        emit('barDragStart', props.columnKey);
        document.body.style.cursor = 'col-resize';
        document.body.style.userSelect = 'none';
        startPositionX = event.x;
        isResizing.value = true;
      },
      onMove: (position => {
        const delta = position.x - startPositionX;
        const newWidth = props.width + delta;
        const finalWidth = newWidth > minWidth ? newWidth : minWidth;
        emit('widthChange', props.columnKey, finalWidth, delta);
        startPositionX = position.x;
      }),
      onEnd: () => {
        emit('barDragEnd', props.columnKey);
        document.body.style.cursor = '';
        document.body.style.userSelect = '';
        isResizing.value = false;
      },
    });

    return {
      resizeBarRef,
      isResizing,
    };
  },
  computed: {
    buttonStyles(): string[] {
      return [
        // Box
        '!rounded-0',

        // Typography
        '!typography-caption-semibold',

        // Theme
        '!bg-background dark:!bg-background-dark',

        // Theme (hover)
        'hover:!bg-primary-opacity-0-08 dark:hover:!bg-primary-opacity-0-08-dark',

        // Theme (active)
        'active:!bg-primary-opacity-0-12 dark:active:!bg-primary-opacity-0-12-dark',

        // Theme (focus)
        'focus:!bg-primary-opacity-0-12 dark:focus:!bg-primary-opacity-0-12-dark',

        // Theme (disabled)
        'disabled:!bg-background disabled:dark:!bg-background-dark',
      ];
    },
    icon(): string {
      switch (this.orderMode) {
        case ContactBooksOrderModes.ASC:
          return 'arrow_downward';
        case ContactBooksOrderModes.DESC:
          return 'arrow_upward';
        default:
          return '';
      }
    },
  },
  methods: {
    changeOrder(): void {
      switch (this.orderMode) {
        case ContactBooksOrderModes.ASC:
          this.$emit('order', ContactBooksOrderModes.DESC);
          break;
        case ContactBooksOrderModes.DESC:
          this.$emit('order', ContactBooksOrderModes.ASC);
          break;
        default:
          this.$emit('order', ContactBooksOrderModes.DESC);
          break;
      }
    },
  },
});
</script>
