<template>
  <OrNotificationV3
    v-if="systemBusy"
    disable-close
    variant="warning"
    class="absolute z-[100] md:left-1/2 md:-translate-x-1/2"
  >
    {{ $t('settings.systemBusy') }}
  </OrNotificationV3>
  <slot />
</template>
<script lang="ts">
import { OrNotificationV3 } from '@onereach/ui-components';
import { useTimeoutPoll } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { defineComponent, onBeforeMount } from 'vue';

import { useSettings } from '@/stores';


export default defineComponent({
  name: 'SettingsContainer',
  components: {
    OrNotificationV3,
  },
  setup() {
    const settingsStore = useSettings();
    const { systemBusy } = storeToRefs(settingsStore);
    const { resume } = useTimeoutPoll(settingsStore.getOperationInfo, 30000);
    onBeforeMount(() => {
      settingsStore.getContactsCount();
      resume();
    });
    return {
      systemBusy,
    };
  },
});
</script>
