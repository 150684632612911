<template>
  <div class="array-of-strings">
    <span
      v-for="val in value"
      :key="val"
      class="string-item"
    >
      {{ val }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Contact } from '@/types';

export default defineComponent({
  name: 'ContactsTableDataCellArrayOfStrings',
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    value(): string[] {
      return (this.contact[this.columnKey] ?? []) as string[];
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.array-of-strings {
  display: flex;
  flex-direction: row;
  gap: $s-3;
  margin: -2px 0;
  overflow: hidden;

  .string-item {
    flex-shrink: 0;
    max-width: 100px;
    height: $s-5;
    padding: 3px $s-4;
    overflow: hidden;
    font-size: $fs-0;
    line-height: $lh-0;
    text-overflow: ellipsis;
    background-color: $c-neutral-1;
    border-radius: 13px;
  }
}
</style>
