import { h } from 'vue';
import { RouteLocationNormalized, RouteRecordRaw, RouterView } from 'vue-router';

import MigrationsLoader from '@/containers/MigrationsLoader.vue';
import i18n from '@/i18n';
import { PaddedLayout, RoutingTabsLayout, SettingsLayout, FullScreenLayout } from '@/layouts';
import { useContactBooksV2 } from '@/stores';
import isContactsV1Enabled from '@/utils/isContactsV1Enabled';
import isContactsV2Enabled from '@/utils/isContactsV2Enabled';


const routes = [
  {
    path: '/',
    component: MigrationsLoader,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        redirect: isContactsV2Enabled()
          ? '/books'
          : '/contact-books',
      },
      ...(isContactsV1Enabled() ? [{
        path: '/contact-books',
        name: 'contact books',
        meta: {
          layout: PaddedLayout,
          breadcrumb: () => {
            return {
              label: 'Contact books',
              to: '/contact-books',
            };
          },
          title: function (this: RouteLocationNormalized) {
            return i18n.global.t('titles.contactBooks');
          },
        },
        children: [
          {
            path: '',
            name: 'books index',
            component: () => import('@/pages/ContactBooks.vue'),
          },
          {
            path: '/contact-books/:bookName',
            component: { render: () => h(RouterView) },
            meta: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              breadcrumb: function (route: RouteLocationNormalized) {
                return {
                  label: route.params.bookName,
                  to: `/contact-books/${route.params.bookName}`,
                };
              },
              title: function (route: RouteLocationNormalized) {
                return i18n.global.t('titles.contactBook', { bookName: route.params.bookName });
              },
            },
            children: [
              {
                path: '',
                name: 'contacts',
                component: () => import('@/pages/Contacts.vue'),
                props: true,
              },
              {
                path: '/contact-books/:bookName/access',
                name: 'contacts access',
                component: () => import('@/pages/AccessSettings.vue'),
                props: true,
                meta: {
                  breadcrumb: function () {
                    return {
                      label: 'Access settings',
                    };
                  },
                  title: function (route) {
                    return i18n.global.t('titles.accessSettings', { bookName: route.params.bookName });
                  },
                },
              },
            ],
          },
        ],
      }] : []),
      ...(isContactsV2Enabled() ? [{
        path: '/books',
        meta: {
          isV2: true,
          layout: RoutingTabsLayout,
          breadcrumb: () => {
            return {
              label: 'Contact books',
              to: '/books',
            };
          },
          title: function () {
            return i18n.global.t('titles.contactBooks');
          },
        },
        children: [
          {
            path: '',
            name: 'books v2 index',
            component: () => import('@/pages/ContactBooksV2.vue'),
          },
          {
            path: '/books/field-schemas',
            name: 'field schemas',
            component: () => import('@/pages/FieldSchemas.vue'),
            meta: {
              title: function () {
                return i18n.global.t('titles.fields');
              },
            },
          },
          {
            path: '/books/create',
            name: 'create book v2',
            component: () => import('@/pages/CreateContactBook.vue'),
            meta: {
              breadcrumb: () => {
                return {
                  label: 'Create a new book',
                  to: '/books/create',
                };
              },
              title: function () {
                return i18n.global.t('titles.createContactBook');
              },
            },
          },
          {
            path: '/books/contacts',
            name: 'all contacts v2',
            component: () => import('@/pages/ContactsV2.vue'),
            meta: {
              title: function () {
                return i18n.global.t('titles.allContacts');
              },
            },
          },
          {
            path: '/books/contacts/import',
            component: () => import('@/pages/ImportContacts.vue'),
            meta: {
              layout: FullScreenLayout,
              title: function () {
                return i18n.global.t('titles.importContacts');
              },
              breadcrumb: function () {
                return {
                  label: 'Import contacts',
                  to: '/books/contacts/import',
                };
              },
            },
          },
          {
            path: '/books/:bookId/:createdContactsCount?/:failedContactsCount?',
            component: { render: () => h(RouterView) },
            meta: {
              breadcrumb: function (route: RouteLocationNormalized) {
                const bookId = route.params.bookId as string;
                const { getBookById } = useContactBooksV2();
                return {
                  label: getBookById(bookId)?.label ?? bookId,
                  to: `/books/${route.params.bookId}`,
                };
              },
              title: async function (route: RouteLocationNormalized) {
                const bookId = route.params.bookId as string;
                const { getBookById, getContactBook } = useContactBooksV2();
                let bookName = getBookById(bookId)?.label;
                if (!bookName) {
                  await getContactBook(bookId);
                  bookName = getBookById(bookId)?.label ?? bookId;
                }
                return i18n.global.t('titles.contactBook', { bookName });
              },
            },
            children: [
              {
                path: '',
                name: 'contacts v2',
                component: () => import('@/pages/ContactsV2.vue'),
                props: true,
              },
              {
                path: '/books/:bookId/import',
                component: () => import('@/pages/ImportContacts.vue'),
                meta: {
                  layout: FullScreenLayout,
                  title: function () {
                    return i18n.global.t('titles.importContacts');
                  },
                  breadcrumb: function (route: RouteLocationNormalized) {
                    return {
                      label: 'Import contacts',
                      to: `/books/${route.params.bookId}/import`,
                    };
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: '/field-schemas',
        redirect: {
          name: 'field schemas',
        },
      }] : [{
        path: '/visibility-rules',
        name: 'visibility rules',
        component: () => import('@/pages/VisibilityRules.vue'),
        meta: {
          breadcrumb: () => {
            return {
              label: 'Visibility rules',
              to: '/visibility-rules',
            };
          },
          title: function () {
            return i18n.global.t('titles.visibilityRules');
          },
        },
      }]),
      {
        path: '/settings',
        meta: {
          layout: SettingsLayout,
          isV2: true,
          isSettings: true,
        },
        children: [
          {
            path: '',
            redirect: '/settings/indices',
          },
          {
            path: '/settings/indices',
            meta: {
              title: function () {
                return i18n.global.t('titles.settings');
              },
              roles: ['ADMIN', 'SUPER_ADMIN'],
            },
            component: () => import(/* webpackChunkName: 'settings' */ '@/pages/IndicesSettings.vue'),
          },
          {
            path: '/settings/deprecated-indices',
            meta: {
              title: function () {
                return i18n.global.t('titles.settings');
              },
              roles: ['ADMIN', 'SUPER_ADMIN'],
            },
            component: () => import(/* webpackChunkName: 'settings' */ '@/pages/DeprecatedIndicesSettings.vue'),
          },
          {
            path: '/settings/migrations',
            meta: {
              title: function () {
                return i18n.global.t('titles.settings');
              },
              roles: ['ADMIN', 'SUPER_ADMIN'],
            },
            component: () => import(/* webpackChunkName: 'settings' */ '@/pages/MigrationsSettings.vue'),
          },
          {
            path: '/settings/changelog',
            meta: {
              title: function () {
                return i18n.global.t('titles.changelog');
              },
            },
            component: () => import(/* webpackChunkName: 'changelog' */ '@/pages/Changelog.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: 'not-found' */ '@/pages/NotFound.vue'),
    meta: {
      title: function () {
        return i18n.global.t('titles.notFound');
      },
    },
  },
] as RouteRecordRaw[];

export default routes;
