export function debouncePromise<T>(
  caller: ()=> Promise<T>,
  delay: number,
): Promise<T> {
  let timeout: NodeJS.Timeout | null = null;

  return new Promise<T>((res, rej) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(
      () => caller().then((x) => res(x)).catch((err) => rej(err)),
      delay
    );
  });
}
