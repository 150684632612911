import { ContactsTableColumnStoredValue } from '@/types/storages';

function getDefaultContactsTableColumnStoredValue(): ContactsTableColumnStoredValue {
  return {
    width: 130,
    isShown: true,
  };
}

export default getDefaultContactsTableColumnStoredValue;
