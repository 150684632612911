import { IndexStatusDto } from '@onereach/types-contacts-api';
import { defineStore } from 'pinia';

import ContactsApi from '@/api/ContactsApiV2';

const useSettings = defineStore('settings', {
  state: () => ({
    indexStatuses: [] as IndexStatusDto[],
    contactsCount: 0,
    operation: false,
    systemBusy: false,
    indicesLoading: false,
  }),
  getters: {
    deprecatedIndices: (state) => {
      return state.indexStatuses.filter((index) => {
        return index.toBeRemoved && index.exists;
      });
    },
    actualIndices: (state) => {
      return state.indexStatuses.filter((index) => {
        return !index.toBeRemoved;
      });
    },
    readyIndices: (state) => {
      return state.indexStatuses.filter((index) => {
        return !index.exists && !index.toBeRemoved;
      });
    },
    appliedIndices: (state) => {
      return state.indexStatuses.filter((index) => {
        return index.exists && !index.toBeRemoved;
      });
    },
  },
  actions: {
    async getIndexStatuses(): Promise<void> {
      this.indicesLoading = true;
      this.indexStatuses = await ContactsApi.administrationApi.getIndicesStatus();
      this.indicesLoading = false;
    },

    async getContactsCount(): Promise<void> {
      this.contactsCount = await ContactsApi.administrationApi.getCountInTable('contact');
    },

    async getOperationInfo(): Promise<void> {
      this.systemBusy = await ContactsApi.administrationApi.isSystemBusy();
    },

    async toggleIndex(index: IndexStatusDto): Promise<void> {
      if (index.exists) {
        await ContactsApi.administrationApi.dropIndex(index);
      } else {
        await ContactsApi.administrationApi.addIndex(index);
      }
    },

    async addIndex(index: IndexStatusDto): Promise<void> {
      await ContactsApi.administrationApi.addIndex(index);
    },

    async dropIndex(index: IndexStatusDto): Promise<void> {
      await ContactsApi.administrationApi.dropIndex(index);
    },
  },
});

export default useSettings;
