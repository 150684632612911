<template>
  <div
    class="condition-item"
    data-test="condition-item"
  >
    <or-select
      v-model="conditionModel.property"
      data-test="column-label-select"
      class="column-label-select"
      :options="schemaOptions"
      :label="$t('contacts.filterModal.conditions.dbColumnLabel')"
      :placeholder="$t('contacts.filterModal.conditions.dbColumnLabelPlaceholder')"
      :is-disabled="isLoading"
      :is-invalid="v$.conditionModel.property.$error"
      :error="v$.conditionModel.property.$silentErrors[0]?.$message"
      @close="v$.conditionModel.property.$touch"
    />
    <div class="composite-box">
      <or-select
        v-model="conditionModel.operator"
        data-test="operator-select"
        class="operator-select"
        :options="operatorOptions"
        :label="$t('contacts.filterModal.conditions.operator')"
        :placeholder="$t('contacts.filterModal.conditions.operatorPlaceholder')"
        :is-disabled="isLoading"
        :is-invalid="v$.conditionModel.operator.$error"
        :error="v$.conditionModel.operator.$silentErrors[0]?.$message"
        @close="v$.conditionModel.operator.$touch"
      />
      <or-chips
        v-if="isArray"
        v-model="conditionModel.value"
        class="value-chips"
        :label="valueLabel"
        :is-disabled="isLoading"
        :is-invalid="v$.conditionModel.value.$error"
        :error="v$.conditionModel.value.$silentErrors[0]?.$message"
        @blur="onBlur"
      />
      <or-date-time-picker
        v-else-if="schemaItem?.typeOf === 'date'"
        v-model="dateTimeValue"
        class="value-date-time-picker"
        :label="valueLabel"
        :is-disabled="isLoading"
        :is-invalid="v$.conditionModel.value.$error"
        :error="v$.conditionModel.value.$silentErrors[0]?.$message"
        @blur="onBlur"
      />
      <or-input
        v-else
        v-model="conditionModel.value"
        data-test="condition-value-textbox"
        class="value-textbox"
        :label="valueLabel"
        :is-disabled="isLoading"
        :is-invalid="v$.conditionModel.value.$error"
        :error="v$.conditionModel.value.$silentErrors[0]?.$message"
        @blur="onBlur"
      />
    </div>
    <or-icon-button
      data-test="delete-item-button"
      class="delete-button"
      icon-color="neutral-3"
      size="small"
      icon="delete"
      :is-disabled="isLoading"
      @click="$emit('delete')"
    />
  </div>
</template>
<script lang="ts">
import { Option, OrChips, OrDateTimePicker, OrIconButton, OrSelect, OrInput } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';

import { SchemaItemTypes } from '@/constants';
import { Condition, Schema, SchemaItem } from '@/types';
import { getFilterOperatorOptions, trimAll } from '@/utils';

const SUPPORTED_TYPES = [
  SchemaItemTypes.ARRAY_OF_OBJECTS,
  SchemaItemTypes.ARRAY_OF_STRINGS,
  SchemaItemTypes.ARRAY_OF_IDENTIFIERS,
  SchemaItemTypes.STRING,
  SchemaItemTypes.IDENTIFIER_OBJECT,
  SchemaItemTypes.INTEGER,
  SchemaItemTypes.DOUBLE,
  SchemaItemTypes.DATE,
];

export default defineComponent({
  name: 'ContactsFilterFormConditionItem',
  components: {
    OrIconButton,
    OrChips,
    OrSelect,
    OrInput,
    OrDateTimePicker,
  },
  props: {
    modelValue: {
      type: Object as PropType<Condition>,
      required: false,
      default: () => {},
    },
    schema: {
      type: Object as PropType<Schema>,
      required: true,
    },
    formItems: {
      type: Set as PropType<Set<unknown>>,
      required: false,
      default: () => new Set(),
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'delete'],
  setup(props, { emit }) {
    const conditionModel = useVModel(props, 'modelValue', emit, { deep: true });

    return {
      v$: useVuelidate({ $scope: 'ContactsFilterEditor' }),
      conditionModel,
    };
  },
  computed: {
    dateTimeValue: {
      get(): Date | undefined {
        return this.conditionModel.value ? new Date(this.conditionModel.value as string) : undefined;
      },
      set(val: Date) {
        this.conditionModel.value = val?.toISOString();
      },
    },
    schemaOptions(): Option[] {
      return _.entries(this.schema).filter(([, item]) => SUPPORTED_TYPES.includes(item.typeOf))
        .flatMap(([name, item]) =>
          (item.properties && !_.isEmpty(item.properties)) ? _.keys(item.properties).map(propertyName => ({
            value: `${name}.${propertyName}`,
            label: `${name}.${propertyName}`,
          })) : (
            {
              value: name,
              label: item.label,
            }))
        .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 :
          b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0);
    },
    operatorOptions(): Option[] {
      if (!this.schemaItem) return getFilterOperatorOptions(SchemaItemTypes.STRING, this.$t);
      return getFilterOperatorOptions(this.schemaItem.typeOf, this.$t);
    },
    schemaItem(): SchemaItem | null {
      if (!this.conditionModel?.property) return null;
      return this.schema[this.conditionModel.property];
    },
    isArray(): boolean {
      return false; //TODO: currently arrays are not fully supported
      /*
      if (!this.schemaItem) return false;
      return this.schemaItem.typeOf.startsWith('array of');
       */
    },
    valueLabel(): string {
      return this.isArray ?
        this.schemaItem?.label as string :
        this.$t('contacts.filterModal.conditions.value');
    },
  },
  watch: {
    'conditionModel.property'() {
      this.conditionModel.operator = '';
      this.conditionModel.value = this.isArray ? [] : '';
      switch (this.schema[this.conditionModel.property]?.typeOf) {
        case SchemaItemTypes.DOUBLE:
        case SchemaItemTypes.INTEGER:
          this.conditionModel.type = 'number';
          break;
        case SchemaItemTypes.DATE:
          this.conditionModel.type = 'date';
          break;
        default:
          this.conditionModel.type = 'string';
          break;
      }
    },
  },
  validations() {
    return {
      conditionModel: {
        property: {
          required,
        },
        operator: {
          required,
        },
        value: {
          required,
        },
      },
    };
  },
  mounted() {
    this.formItems.add(this);
  },
  beforeUnmount() {
    this.formItems.delete(this);
  },
  methods: {
    onBlur() {
      this.conditionModel.value = trimAll(this.conditionModel.value);
      (this.v$.conditionModel as any).value.$touch();
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.condition-item {
  display: flex;
  flex-direction: row;
  gap: $s-3;

  .composite-box {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: start;
    width: auto;

  }

  :deep(.or-textbox) .or-label,
  :deep(.or-select) .or-label {
    margin-bottom: $s-1;
  }

  .column-label-select {
    width: 25%;
    min-width: 25%;
    margin-right: $s-3;
  }

  .operator-select {
    width: $s-9;
    margin-right: -1px;

    :deep(.or-select-input) {
      background-color: $c-neutral-0;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .value-chips :deep(.or-chips-input),
  .value-textbox :deep(.or-textbox--input),
  .value-date-time-picker :deep(.or-date-time-picker-input-wrapper) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .delete-button {
    margin-top: px-to-rem(28);
  }
}
</style>
