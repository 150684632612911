<template>
  <component
    :is="component"
    v-if="!isEmpty"
    :contact="contact"
    :column-key="columnKey"
  />
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import _ from 'lodash';
import { CompositeColumnKeys, SchemaItemTypes } from '@/constants';
import { Contact } from '@/types';
import { hasOwnProperty } from '@/utils';

import ContactsTableDataCellString from './ContactsTableDataCellString.vue';
import ContactsTableDataCellDate from './ContactsTableDataCellDate.vue';
import ContactsTableDataCellArrayOfStrings from './ContactsTableDataCellArrayOfStrings.vue';
import ContactsTableDataCellArrayOfObjects from './ContactsTableDataCellArrayOfObjects.vue';
import ContactsTableDataCellArrayOfIdentifiers from './ContactsTableDataCellArrayOfIdentifiers.vue';
import ContactsTableDataCellIdentifierObject from './ContactsTableDataCellIdentifierObject.vue';
import ContactsTableCompositeDataCellProfileInfo from './ContactsTableCompositeDataCellProfileInfo.vue';

const componentsMap = {
  [SchemaItemTypes.STRING]: ContactsTableDataCellString,
  [SchemaItemTypes.DATE]: ContactsTableDataCellDate,
  [SchemaItemTypes.DOUBLE]: ContactsTableDataCellString,
  [SchemaItemTypes.MD]: ContactsTableDataCellString,
  [SchemaItemTypes.INTEGER]: ContactsTableDataCellString,
  [SchemaItemTypes.IDENTIFIER_OBJECT]: ContactsTableDataCellIdentifierObject,
  [SchemaItemTypes.ARRAY_OF_STRINGS]: ContactsTableDataCellArrayOfStrings,
  [SchemaItemTypes.ARRAY_OF_OBJECTS]: ContactsTableDataCellArrayOfObjects,
  [SchemaItemTypes.ARRAY_OF_IDENTIFIERS]: ContactsTableDataCellArrayOfIdentifiers,
};

const compositeComponentsMap = {
  [CompositeColumnKeys.NAME]: ContactsTableCompositeDataCellProfileInfo,
};

export default defineComponent({
  name: 'ContactsTableDataCell',
  props: {
    type: {
      type: String as PropType<SchemaItemTypes>,
      required: false,
      default: null,
    },
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    columnKey: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  setup() {
    return {
      componentsMap,
      compositeComponentsMap,
    };
  },
  computed: {
    isComposite(): boolean {
      return _.isNil(this.type);
    },
    isEmpty(): boolean {
      return !this.isComposite && !this.contact[this.columnKey] && _.isEmpty(this.contact[this.columnKey]);
    },
    component(): any {
      return hasOwnProperty(this.componentsMap, this.type)
        ? this.componentsMap[this.type]
        : this.isComposite && hasOwnProperty(this.compositeComponentsMap, this.columnKey)
          ? this.compositeComponentsMap[this.columnKey]
          : null;
    },
  },
});
</script>
