import { ContactBookFieldSchemaDto, ColumnTypes } from '@onereach/types-contacts-api';

import { ExtendedContactBookFieldSchemaDto } from '@/types';

export function getExtendedFieldsSchemas(
  fieldSchemas: ContactBookFieldSchemaDto[],
): ExtendedContactBookFieldSchemaDto[] {
  return [
    {
      id: 'id',
      label: 'Id',
      type: ColumnTypes.string,
      isContactField: true,
      machine_name: '_id',
    },
    ...fieldSchemas,
    {
      id: 'created_at',
      label: 'Created at',
      type: ColumnTypes.date,
      isContactField: true,
      machine_name: '_created_at',
    },
    {
      id: 'updated_at',
      label: 'Updated at',
      type: ColumnTypes.date,
      isContactField: true,
      machine_name: '_updated_at',
    },
  ];
}
