import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';

import config from '@/config';
import { UnpackedError } from '@/errors';
import useAuthStore from '@/stores/auth';

import pkg from '../package.json';

export default class SentryApi {
  static init(app: App, router: Router) {
    try {
      Sentry.init({
        app,
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENV,
        replaysSessionSampleRate: config.SENTRY_REPLAY_SESSIONS,
        replaysOnErrorSampleRate: config.SENTRY_ERROR_REPLAY_SESSIONS,
        beforeSend(event) {
          if (SentryApi.isDevMode()) {
            return null;
          }
          const { username, accountId, role, userId } = useAuthStore();
          event.tags = {
            userId: userId,
            accountId: accountId,
            feature: config.FEATURE,
            username: username,
            role: role,
          };

          return event;
        },
        release: `${config.SENTRY_PROJECT}@${pkg.version}`,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration(),
        ],
        attachStacktrace: true,
        maxBreadcrumbs: config.SENTRY_MAX_BREADCRUMBS,
        tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE,
      });
    } catch (e: unknown) {
      console.error(`Can not initiate Sentry due to: ${e}`);
    }
  }

  static captureException(err: unknown) {
    const unpackedError = new UnpackedError(err);
    if (!SentryApi.isDevMode()) {
      Sentry.captureException(unpackedError);
    }
    console.error(unpackedError);
  }

  private static isDevMode() {
    return process.env.NODE_ENV === 'development';
  }
}
