<template>
  <OrModalV3
    ref="modalComponent"
    size="m"
  >
    <template #header>
      {{ $t('contacts.mergeModal.title') }}
    </template>
    <div
      class="merge-content"
      data-test="merge-content"
    >
      <div class="contact-row radio-row">
        <div
          v-for="contact of contacts"
          :key="contact.id"
          class="contact-cell radio-cell"
          data-test="main-contact-radio-cell"
          :class="{ selected: contact.id === selectedId }"
          @click="selectContact(contact.id)"
        >
          <or-radio
            v-model="selected"
            :value="contact.id"
            :label="$t('contacts.mergeModal.mainContact')"
          />
        </div>
      </div>
      <div class="contact-info">
        <div
          v-for="field of fieldSchemasToShow"
          :key="field.id"
          class="contact-row"
        >
          <div
            v-for="contact of contacts"
            :key="contact.id"
            class="contact-cell"
            :class="{
              selected: contact.id === selectedId,
              deleting: !unionTypes.includes(field.type)
            }"
            @click="selectContact(contact.id)"
          >
            <contacts-view-field
              :field-schema="field"
              :data="contact.fieldValues[field.id]?.value"
              :always-show="propertiesToShow[field.id]"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="actions">
        <or-button
          type="outline"
          :content="$t('common.cancel')"
          data-test="cancel-merge-contacts-button"
          @click="$emit('close')"
        />
        <or-button
          :is-loading="isMerging"
          :content="$t('contacts.mergeModal.merge')"
          data-test="merge-contacts-button"
          @click="$emit('merge', selectedId, unselectedId)"
        />
      </div>
    </template>
  </OrModalV3>
</template>
<script lang="ts">
import { ColumnTypes, ContactBookFieldSchemaDto, ContactResponseDto } from '@onereach/types-contacts-api';
import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton, OrRadio } from '@onereach/ui-components-legacy';
import { defineComponent, PropType, ref, computed } from 'vue';

import { ContactPropertiesToShow } from '@/types';
import { getContactPropertiesToShowV2 } from '@/utils';

import { ContactsViewFieldV2 } from '../ContactsView/ContactsViewField';

const unionTypes: ColumnTypes[] = [
  ColumnTypes['array of objects'],
  ColumnTypes['array of strings'],
  ColumnTypes['array of identifiers'],
];

export default defineComponent({
  name: 'ContactMergeModal',
  components: {
    OrModalV3,
    OrButton,
    OrRadio,
    ContactsViewField: ContactsViewFieldV2,
  },
  props: {
    isMerging: {
      type: Boolean,
      required: false,
      default: false,
    },
    fieldSchemas: {
      type: Array as PropType<ContactBookFieldSchemaDto[]>,
      required: false,
      default: () => [],
    },
    contacts: {
      type: Array as PropType<ContactResponseDto[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['merge', 'close'],
  expose: ['modalComponent'],
  setup(props) {
    let mainContact = ref('');
    const selected = computed(() => {
      return (mainContact.value === '') ? props.contacts[0]?.id : mainContact.value;
    });
    return {
      modalComponent: ref<InstanceType<typeof OrModalV3>>(),
      unionTypes,
      selected,
      mainContact,
    };
  },
  computed: {
    selectedId(): string {
      return (this.selected as unknown as ({ value: string; }))?.value ?? this.selected;
    },
    unselectedId(): string {
      return this.contacts[0]?.id === this.selectedId ?
        this.contacts[1]?.id :
        this.contacts[0]?.id;
    },
    fieldSchemasToShow(): ContactBookFieldSchemaDto[] {
      return this.fieldSchemas.filter(({ id }) => this.propertiesToShow[id as string]);
    },
    propertiesToShow(): ContactPropertiesToShow {
      return getContactPropertiesToShowV2(this.contacts);
    },
  },
  methods: {
    selectContact(contactId: string): void {
      this.mainContact = contactId;
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.merge-content {
  .contact-row {
    display: flex;
    flex-direction: row;
    gap: $s-4;
    justify-content: space-around !important;
    background-color: $c-neutral-0;

    & > .contact-cell {
      flex: 1 1 0;
      min-width: 0;
      padding: $s-4 $s-4 0;
      background-color: $c-neutral-1;
      border: 1px solid $c-neutral-1-darken-5;
      border-top-width: 0;
      border-bottom-width: 0;
      @apply hover:bg-primary-opacity-0-08 dark:hover:bg-primary-opacity-0-08-dark;

      &.selected {
        @apply bg-primary-opacity-0-08 dark:bg-primary-opacity-0-08-dark;
        // background-color: $c-primary-lighten-45;
        border-color: $c-primary;
      }

      &:not(.selected) {
        cursor: pointer;
      }

      &:not(.selected).deleting :deep(.text) {
        text-decoration: line-through;
      }

      & > * {
        height: 100%;
      }
    }

    & > .radio-cell {
      border-top-width: 1px;
      border-radius: $s-2 $s-2 0 0;
    }

    &:last-child > .contact-cell {
      border-bottom-width: 1px;
      border-radius: 0 0 $s-2 $s-2;
    }
  }

  .contact-info > .contact-row {
    &:first-child > .contact-cell {
      padding-top: $s-5;
    }

    &:not(:last-child, :first-child) > .contact-cell > * {
      border-bottom: 1px solid $c-neutral-2;
    }
  }
}

.contacts-view {
  display: flex;
  flex-direction: column;
  gap: $s-4;
}

.actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
</style>
