import RethrownError from './RethrownError';

class TranslatableError extends RethrownError {
  constructor(
    public readonly translationKey: string,
    public readonly innerError?: Error | unknown
  ) {
    const e = innerError instanceof Error ? innerError : new Error('' + innerError);
    super(`An error occurred: ${translationKey}.`, e);
    Object.setPrototypeOf(this, TranslatableError.prototype);
  }
}

export default TranslatableError;
