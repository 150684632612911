import { createI18n } from 'vue-i18n';
import en from './locales/en.json';

const datetimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};
const messages = {
  en,
};
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  // @ts-ignore
  datetimeFormats,
});

export default i18n;
