<template>
  <div class="app-page flex flex-col h-full bg-surface-1 dark:bg-surface-1-dark">
    <ServiceNavigationHeader
      :config="config"
      :service-translations="serviceTranslations"
      :token="token"
      :account-id="accountId"
      :breadcrumb-items="breadcrumbs"
      :menu-items="menuItems"
      :user-role="userRole"
      data-test="header"
    >
      <template #account>
        <AuthPopover
          :token="token"
          :dark-mode-options="{
            attribute: 'data-theme',
            valueDark: 'dark',
          }"
        />
      </template>
    </ServiceNavigationHeader>
    <OrNotificationV3
      v-if="isErrorShown"
      class="error-notification absolute z-[100] w-[calc(100%-(2*theme(spacing.3xl)))] m-3xl"
      variant="error"
      @close="isErrorShown=false"
    >
      {{ errorText }}
    </OrNotificationV3>
    <div
      id="page-content-container"
      class="relative grow-1 min-h-[0] h-full w-full"
    >
      <div
        id="page-content"
        class="page-content relative overflow-auto h-full w-full m-auto"
      >
        <component :is="layout">
          <slot />
        </component>
      </div>
    </div>
    <OrToastContainerV3 />
  </div>
</template>
<script lang="ts">
import { AuthPopover, UserRoles } from '@onereach/auth-ui-module';
import { ServiceNavigationHeader, LocalizedServiceTranslations } from '@onereach/service-navigation';
import { OrNotificationV3, OrToastContainerV3 } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { computed, defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router';

import i18n from '@/i18n';
import { getMenuItems } from '@/utils';

export default defineComponent({
  name: 'AppPage',
  components: {
    ServiceNavigationHeader,
    AuthPopover,
    OrNotificationV3,
    OrToastContainerV3,
  },
  props: {
    token: {
      type: String,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: true,
    },
    serviceTranslations: {
      type: Object as PropType<LocalizedServiceTranslations>,
      required: false,
      default: null,
    },
    showError: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorText: {
      type: String,
      required: false,
      default: null,
    },
    accountId: {
      type: String,
      required: false,
      default: null,
    },
    userRole: {
      type: String as PropType<UserRoles>,
      default: undefined,
    },
  },
  emits: ['update:showError'],
  setup(props, { emit }) {
    const isErrorShown = useVModel(props, 'showError', emit);

    const route = useRoute();

    const menuItems = computed(() => getMenuItems(i18n.global.t, props.userRole));

    const breadcrumbs = computed(() => {
      return route.matched
        .map(({ meta }) => meta.breadcrumb)
        .filter(Boolean)
        .map((breadcrumb) => {
          return typeof breadcrumb === 'function'
            ? breadcrumb(route)
            : breadcrumb;
        });
    });

    const layout = computed(() => {
      return route.matched
        .map(({ meta }) => meta.layout)
        .filter(Boolean)
        .slice(-1)[0];
    });

    return {
      menuItems,
      isErrorShown,
      breadcrumbs,
      layout,
    };
  },
});
</script>

