<template>
  <div class="profile-container">
    <or-avatar
      :src="profileImage"
      class="picture"
    />
    <div class="name">
      {{ name }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Contact } from '@/types';
import { OrAvatar } from '@onereach/ui-components-legacy';

export default defineComponent({
  name: 'ContactsTableCompositeDataCellProfileInfo',
  components: {
    OrAvatar,
  },
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
  },
  computed: {
    profileImage(): string {
      return this.contact?.profileImage as string | undefined ?? '';
    },
    firstName(): string {
      return this.contact?.firstName as string | undefined ?? '';
    },
    lastName(): string {
      return this.contact?.lastName as string | undefined ?? '';
    },
    hasName(): boolean {
      return !!this.firstName || !!this.lastName;
    },
    name(): string {
      return this.hasName ? `${this.firstName} ${this.lastName}` : this.$t('contacts.noName');
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.profile-container {
  display: flex;
  flex-direction: row;
  gap: $s-3;
  padding: $s-3 0 $s-3 $s-4 !important;
}

.picture {
  flex-shrink: 0;
}

.name {
  align-self: center;
  font-size: $fs-1;
}
</style>
