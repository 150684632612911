<template>
  <div class="identifiers">
    <span
      v-for="(identifier, index) in value"
      :key="index"
      class="identifier-value"
    >
      {{ identifier.val }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Contact, IdentifierValue } from '@/types';

export default defineComponent({
  name: 'ContactsTableDataCellArrayOfIdentifiers',
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    value(): IdentifierValue[] {
      return (this.contact[this.columnKey] ?? []) as IdentifierValue[];
    },
  },
});
</script>
<style lang="scss" scoped>
.identifiers {
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: auto;

  .identifier-value {
    flex-shrink: 0;
    max-width: 100%;
  }
}
</style>
