import _ from 'lodash';
import TranslatableError from './TranslatableError';
import RethrownError from './RethrownError';

class UnpackedError extends RethrownError {
  private static unpackErrorMessageFrom(error: Error | unknown, skipTranslatableErrors: boolean): string {
    let message = (error as Error)?.message ?? ('' + error);
    if (error instanceof TranslatableError) {
      const innerErrorMessage = UnpackedError.unpackErrorMessageFrom(error.innerError, skipTranslatableErrors);
      message = skipTranslatableErrors ? innerErrorMessage : (message + '\n' + innerErrorMessage);
    } else if ('cause' in (error as any) && _.isFunction((error as any).cause)) { // unpack SDK error
      const cause = (error as any).cause();
      const errorMessage = _.get(cause, 'response.data.error') as string | unknown;
      if (typeof errorMessage === 'string') message += '\nCause: ' + errorMessage;
    }
    return message;
  }

  constructor(public readonly error: Error | unknown, skipTranslatableErrors = false) {
    const e = error instanceof Error ? error : new Error('' + error);
    super(UnpackedError.unpackErrorMessageFrom(error, skipTranslatableErrors), e, true);
    this.name = e.name;
    Object.setPrototypeOf(this, UnpackedError.prototype);
  }
}

export default UnpackedError;
