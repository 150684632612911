import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useRouteMeta() {
  const route = useRoute();

  const isV2 = computed(() => route.matched.some(r => r.meta.isV2));
  const isSettings = computed(() => route.matched.some(r => r.meta.isSettings));

  return {
    isV2,
    isSettings,
  };
}

export default useRouteMeta;
