<template>
  <div class="h-full w-full">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FullScreenLayout',
  setup() {
    return {};
  },
});
</script>
