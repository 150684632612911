import { ColumnTypes, ContactBookFieldSchemaDto } from '@onereach/types-contacts-api';
import _ from 'lodash';

import { ContactsOrder, ContactsTableStoredValueV2 } from '@/types';

import getDefaultContactsTableColumnStoredValue from './getDefaultContactsTableColumnStoredValueV2';


function getDefaultContactsTableStoredValue(
  fieldSchemas: ContactBookFieldSchemaDto[],
  includeContactFields = true,
): ContactsTableStoredValueV2 {
  const extendedFieldSchemas = [
    ...(includeContactFields ? [{
      id: 'id',
      label: 'Id',
      type: ColumnTypes.string,
    }] : []),
    ...fieldSchemas,
    ...(includeContactFields ? [
      {
        id: 'created_at',
        label: 'Created at',
        type: ColumnTypes.date,
      },
      {
        id: 'updated_at',
        label: 'Updated at',
        type: ColumnTypes.date,
      },
    ] : []),
  ] as ContactBookFieldSchemaDto[];
  const columns = _.mapValues(_.keyBy(extendedFieldSchemas, ({ id }) => id),
    getDefaultContactsTableColumnStoredValue);

  const order = {
    column: includeContactFields ? 'created_at' : (fieldSchemas[0]?.id ?? ''),
    mode: 'desc',
  } as ContactsOrder;

  const paginationLimit = 10;

  return {
    columns,
    order,
    paginationLimit,
  };
}

export default getDefaultContactsTableStoredValue;
