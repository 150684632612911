import { Accounts } from '@or-sdk/accounts';
import _ from 'lodash';

import { useAuth } from '@/stores';

import config from '../config';

const instance = new Accounts({
  sdkUrl: `${config.SDK_API_URL}`,
  token: () => {
    return `USER ${_.get(useAuth(), 'reactiveToken')}`;
  },
});

export default instance;
