import { piniaAuthRouterMiddleware } from '@onereach/auth-ui-module';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import config from '@/config';
import i18n from '@/i18n';
import { useAuth } from '@/stores';

import { roleGuard } from './roleGuard';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await piniaAuthRouterMiddleware(to, useAuth(), config.AUTH_UI_URL);
  const requiredRole = to.meta.roles;
  if (requiredRole && !roleGuard(requiredRole)) {
    next('/');
  } else {
    next();
  }
});

router.afterEach((to) => {
  // See https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(async () => {
    document.title = await (typeof to.meta.title === 'function'
      ? to.meta.title(to)
      : i18n.global.t('titles.contacts'));
  });
});

export default router;
