import {
  BulkCreateResultsDto,
  ContactListDto,
  ContactResponseDto,
  CreateContactDto,
  CreateMultipleContactsDto,
  FindAllParamsDto,
  LinkContactMultiParamsDto,
  LinkContactParamsDto,
  MergeContactParamsDto,
  UnlinkContactMultiParamsDto,
  UnlinkContactParamsDto,
  UpdateContactDto,
} from '@onereach/types-contacts-api';
import { defineStore } from 'pinia';

import ContactsApi from '@/api/ContactsApiV2';

type Collections = Record<string, ContactListDto>;

type Contacts = Record<string, ContactResponseDto>;

const useContactsStore = defineStore('contactsV2', {
  state: () => ({
    all: {
      items: [],
      total: 0,
    } as ContactListDto,
    collections: {} as Collections,
    contacts: {} as Contacts,
  }),

  getters: {
    getContactsByBookId: (state) => {
      return (id: string): ContactListDto => {
        return state.collections[id]
          ? state.collections[id]
          : {} as ContactListDto;
      };
    },

    getAllContacts: (state) => {
      return (): ContactListDto => {
        return state.all as ContactListDto;
      };
    },

    getContactById: (state) => (contactId: string): ContactResponseDto => state.contacts[contactId],
  },

  actions: {
    async getContacts(payload: FindAllParamsDto): Promise<void> {
      const collection = await ContactsApi.contactApi.listContact(payload);

      collection.items.forEach(contact => this.contacts[contact.id] = contact);
      if (payload.contact_book) {
        this.collections[payload.contact_book] = collection;
      } else {
        this.all = collection;
      }
    },

    _updateContact(contact: ContactResponseDto, contactBook?: string): void {
      this.contacts[contact.id] = contact;
      if (contactBook) {
        const collection = this.collections[contactBook];
        if (collection) {
          const index = collection.items.findIndex(ct => ct.id === contact.id);
          if (index >= 0) {
            collection.items[index] = contact;
          } else {
            collection.items.push(contact);
          }
        }
      }
    },

    async updateContact(id: string, data: UpdateContactDto): Promise<void> {
      const contact = await ContactsApi.contactApi.updateContact(id, data);
      this._updateContact(contact, data.contact_book);
    },

    async createContact(data: CreateContactDto): Promise<void> {
      const contact = await ContactsApi.contactApi.createContact(data);
      this._updateContact(contact, data.contact_book);
    },

    async bulkCreateContacts(data: CreateMultipleContactsDto): Promise<BulkCreateResultsDto> {
      return ContactsApi.bulkCreateApi.bulkCreateContacts(`ui_bulk_${Date.now()}`, data);
    },

    async deleteContacts(contactIds: string[], bookId?: string): Promise<void> {
      await ContactsApi.contactApi.deleteMulti({
        contact_book: bookId,
        ids: contactIds,
      });

      contactIds.forEach(id => delete this.contacts[id]);
      if (bookId && this.collections[bookId]) {
        this.collections[bookId].items = this.collections[bookId].items
          .filter(ct => !contactIds.includes(ct.id));
        this.collections[bookId].total -= contactIds.length;
      }
    },

    async deleteAllContacts(contactBookId: string): Promise<void> {
      await ContactsApi.contactApi.deleteMulti({
        contact_book: contactBookId,
        all: true,
      });

      this.collections[contactBookId].items = [];
      this.collections[contactBookId].total = 0;
    },

    async mergeContacts(toId: string, fromId: string, bookId: string | undefined): Promise<void> {
      await ContactsApi.contactApi.mergeContacts(toId, {
        contact_book: bookId,
        mergeContactId: fromId,
      } as MergeContactParamsDto);
    },

    async linkContact(id: string, data: LinkContactParamsDto): Promise<void> {
      await ContactsApi.contactApi.linkContact(id, data);
    },

    async linkContactsMulti(data: LinkContactMultiParamsDto): Promise<void> {
      await ContactsApi.contactApi.linkContactsMulti(data);
    },

    async unlinkContact(id: string, data: UnlinkContactParamsDto): Promise<void> {
      await ContactsApi.contactApi.unlinkContact(id, data);
    },

    async unlinkContactsMulti(data: UnlinkContactMultiParamsDto): Promise<void> {
      await ContactsApi.contactApi.unlinkContactsMulti(data);
    },
  },
});

export default useContactsStore;
