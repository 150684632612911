<template>
  <div class="text text-ellipsis overflow-hidden pb-md+ pt-sm md:pt-sm+">
    {{ text }}
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactViewFieldDate',
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: [Date, String],
      required: false,
      default: null,
    },
  },
  computed: {
    date(): Date | null {
      if (!this.data) return null;
      return new Date(this.data);
    },
    text(): string {
      return this.date?.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hourCycle: 'h12',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC',
      }) ?? '';
    },
  },
});
</script>
