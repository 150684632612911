<template>
  <!--TODO: replace with OrTimeFormat?-->
  <div>{{ text }}</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';


import { DatePropertyType } from '@/types';

export default defineComponent({
  name: 'ContactsTableDataCellDate',
  props: {
    value: {
      type: [String, Date, Number] as PropType<DatePropertyType>,
      required: false,
      default: null,
    },
  },
  computed: {
    text(): string {
      return this.value
        ? new Date(this.value).toLocaleString('en-US', { timeZone: 'UTC' })
        : '';
    },
  },
});
</script>
