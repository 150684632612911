import { ComponentCustomProperties } from '@vue/runtime-core';
import { Option } from '@onereach/ui-components-legacy';
import { SchemaItemTypes } from '@/constants';

function getFilterOperatorOptions(type: SchemaItemTypes, $t: ComponentCustomProperties['$t']): Option[] {
  switch (type) {
    case SchemaItemTypes.INTEGER:
    case SchemaItemTypes.DOUBLE:
    case SchemaItemTypes.DATE:
      return [
        {
          value: '=',
          label: $t('contacts.filterModal.conditions.operators.equal'),
        },
        {
          value: '<',
          label: $t('contacts.filterModal.conditions.operators.less'),
        },
        {
          value: '<=',
          label: $t('contacts.filterModal.conditions.operators.lessOrEqual'),
        },
        {
          value: '>',
          label: $t('contacts.filterModal.conditions.operators.greater'),
        },
        {
          value: '>=',
          label: $t('contacts.filterModal.conditions.operators.greaterOrEqual'),
        },
        {
          value: '!=',
          label: $t('contacts.filterModal.conditions.operators.notEqual'),
        },
      ];
    case SchemaItemTypes.STRING:
    case SchemaItemTypes.ARRAY_OF_STRINGS:
      return [
        {
          value: '=',
          label: $t('contacts.filterModal.conditions.operators.equal'),
        },
        {
          value: 'contains',
          label: $t('contacts.filterModal.conditions.operators.contain'),
        },
      ];
    default:
      return [];
  }
}

export default getFilterOperatorOptions;
