<template>
  <!--TODO: replace with OrTimeFormat?-->
  <div>{{ text }}</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Contact, DatePropertyType } from '@/types';

export default defineComponent({
  name: 'ContactsTableDataCellDate',
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    value(): DatePropertyType | null {
      const value = this.contact[this.columnKey] as string | Date | undefined;
      return value ? new Date(value) : null;
    },
    text(): string {
      return this.value
        ? this.value.toLocaleString('en-US', { timeZone: 'UTC' })
        : '';
    },
  },
});
</script>
