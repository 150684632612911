<template>
  <OrModalV3
    ref="modalComponent"
    data-test="contact-info-modal"
    size="s"
  >
    <template #header>
      <contacts-view-modal-profile-info
        :first-name="firstName"
        :last-name="lastName"
        :profile-image="profilePictureUrl"
      />
    </template>
    <div class="contacts-view">
      <contacts-view-field
        v-for="field of orderedSchemaItems"
        :key="field.order"
        :field-schema="field"
        :data="contact[field.key]"
      />
    </div>
    <template
      v-if="!isReadonly"
      #footer
    >
      <div class="flex justify-end gap-md">
        <or-button
          data-test="edit-contact-info-button"
          type="outline"
          :content="$t('contacts.edit')"
          @click="$emit('edit', contact.contactId)"
        />
      </div>
    </template>
  </OrModalV3>
</template>
<script lang="ts">
import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import { defineComponent, PropType, ref } from 'vue';

import { CompositeColumnKeys } from '@/constants';
import { Contact, Schema, SchemaItemExtended } from '@/types';
import { getCompositeColumnsMapping, convertSchemaToOrderedArray } from '@/utils';

import { ContactsViewModalProfileInfo, ContactsViewField } from './ContactsViewField';

const alreadyShownKeys = getCompositeColumnsMapping()[CompositeColumnKeys.NAME].parts;

export default defineComponent({
  name: 'ContactViewModal',
  components: {
    OrModalV3,
    OrButton,
    ContactsViewModalProfileInfo,
    ContactsViewField,
  },
  props: {
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    schema: {
      type: Object as PropType<Schema>,
      required: false,
      default: () => null,
    },
    contact: {
      type: Object as PropType<Contact>,
      required: false,
      default: () => null,
    },
  },
  expose: ['modalComponent'],
  setup() {
    return {
      modalComponent: ref<InstanceType<typeof OrModalV3>>(),
    };
  },
  computed: {
    orderedSchemaItems(): SchemaItemExtended[] {
      return convertSchemaToOrderedArray(this.schema).filter(({ key }) => !alreadyShownKeys.includes(key));
    },
    firstName(): string | undefined {
      return this.contact.firstName as string | undefined;
    },
    lastName(): string | undefined {
      return this.contact.lastName as string | undefined;
    },
    profilePictureUrl(): string | undefined {
      return this.contact.profileImage as string | undefined;
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.or-modal :deep(.header) {
  align-items: start;
  padding: $s-4 $s-5 !important;
  margin-bottom: $s-4;
}

.contacts-view {
  display: flex;
  flex-direction: column;
  gap: $s-4;
  overflow-x: hidden;

  :deep(*) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
