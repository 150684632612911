<template>
  <div class="layout-column">
    <RouterLink
      v-for="menuItem in menuItems"
      :key="menuItem.label"
      :to="menuItem.to"
      exact-active-class="!typography-body-2-bold bg-primary-opacity-0-08 dark:bg-primary-opacity-0-08-dark md:rounded-md"
    >
      <OrMenuItemV3

        :icon="menuItem.icon"
      >
        {{ menuItem.label }}
      </OrMenuItemV3>
    </RouterLink>
  </div>
</template>
<script lang="ts">
import { OrMenuItemV3 } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';

export default defineComponent({
  name: 'SettingsMenu',
  components: {
    RouterLink,
    OrMenuItemV3,
  },
  setup() {
    const menuItems = [
      {
        label: 'Indices',
        to: '/settings/indices',
        icon: 'tag',
      },
      {
        label: 'Deprecated indices',
        to: '/settings/deprecated-indices',
        icon: 'do_not_disturb_on',
      },
      {
        label: 'Migrations',
        to: '/settings/migrations',
        icon: 'sync',
      },
      {
        label: 'Changelog',
        to: '/settings/changelog',
        icon: 'assignment',
      },
    ];
    return {
      menuItems,
    };
  },
});
</script>
