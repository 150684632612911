<template>
  <div
    class="contact-form-field text-ellipsis"
  >
    <div v-if="!isComponentImplemented">
      {{ fieldSchema.type }}
    </div>
    <or-expansion-panel
      v-else-if="showExpansionPanel"
      class="pt-md"
      :label="fieldSchema.label"
      is-open
    >
      <component
        :is="component"
        class="pt-md"
        :field-schema="oldFieldSchema"
        :data="data"
        @update:data="$emit('update', $event)"
      />
    </or-expansion-panel>
    <div
      v-else
      class="contact-form-field-content"
    >
      <component
        :is="component"
        :field-schema="oldFieldSchema"
        :data="data"
        @update:data="$emit('update', $event)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { ColumnTypes, ContactBookFieldSchemaDto } from '@onereach/types-contacts-api';
import { OrExpansionPanelV3 as OrExpansionPanel } from '@onereach/ui-components';
import { defineComponent, PropType } from 'vue';

import { SchemaItemTypes } from '@/constants';
import { SchemaItem } from '@/types';
import { ColumnTypesExtended } from '@/types/columnTypes';
import { hasOwnProperty } from '@/utils';

import ContactFormFieldArrayOfIdentifiers from './ContactFormFieldArrayOfIdentifiers.vue';
import ContactFormFieldArrayOfObjects from './ContactFormFieldArrayOfObjects.vue';
import ContactFormFieldArrayOfStrings from './ContactFormFieldArrayOfStrings.vue';
import ContactFormFieldDate from './ContactFormFieldDateV2.vue';
import ContactFormFieldDouble from './ContactFormFieldDouble.vue';
import ContactFormFieldIdentifierObject from './ContactFormFieldIdentifierObject.vue';
import ContactFormFieldInteger from './ContactFormFieldInteger.vue';
import ContactFormFieldMD from './ContactFormFieldMD.vue';
import ContactFormFieldString from './ContactFormFieldString.vue';


const FieldComponents = {
  [ColumnTypes.string]: ContactFormFieldString,
  [ColumnTypes.date]: ContactFormFieldDate,
  [ColumnTypesExtended.dateTz]: ContactFormFieldDate,
  [ColumnTypes.double]: ContactFormFieldDouble,
  [ColumnTypes.md]: ContactFormFieldMD,
  [ColumnTypes.integer]: ContactFormFieldInteger,
  [ColumnTypes['array of strings']]: ContactFormFieldArrayOfStrings,
  [ColumnTypes['array of objects']]: ContactFormFieldArrayOfObjects,
  [ColumnTypes['array of identifiers']]: ContactFormFieldArrayOfIdentifiers,
  [ColumnTypes['identifier object']]: ContactFormFieldIdentifierObject,
};

export default defineComponent({
  name: 'ContactFormField',
  components: {
    OrExpansionPanel,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<ContactBookFieldSchemaDto>,
      required: true,
    },
    data: {
      type: [Object, String, Number],
      required: false,
      default: () => undefined,
    },
  },
  emits: ['update'],
  setup() {
    return {
      FieldComponents,
    };
  },
  computed: {
    oldFieldSchema(): SchemaItem { //TODO: remove after migration to V2 API
      return {
        visibility: true,
        label: this.fieldSchema.label,
        singularLabel: this.fieldSchema.singular_label,
        order: 0,
        properties: this.fieldSchema.properties,
        triggers: [],
        typeOf: this.fieldSchema.type as unknown as SchemaItemTypes,
      };
    },
    isComponentImplemented(): boolean {
      return this.fieldSchema.type in FieldComponents;
    },
    component(): any {
      return hasOwnProperty(FieldComponents, this.fieldSchema.type)
        ? FieldComponents[this.fieldSchema.type]
        : null;
    },
    showExpansionPanel(): boolean {
      return ![
        ColumnTypes.string,
        ColumnTypes.double,
        ColumnTypes.integer,
        ColumnTypes.md,
        ColumnTypes.date,
        ColumnTypes['array of strings'],
        ColumnTypesExtended.dateTz,
      ].includes(this.fieldSchema.type);
    },
  },
});

</script>
