import { ObjectValue, Properties } from '@/types';

function getDefaultObjectValue(properties: Properties): ObjectValue {
  const object: ObjectValue = {};
  Object.keys(properties).forEach((k: string) => {
    object[k] = properties[k] === 'string'
      ? ''
      : [];
  });
  return object;
}

export default getDefaultObjectValue;
