import _ from 'lodash';

function trimAll<T extends string | string[]>(val: T, removeEmpty = true): T {
  if (_.isArray(val)) {
    if (removeEmpty) {
      return _.filter(_.map(val, _.trim), _.negate(_.isEmpty)) as T;
    } else {
      return _.map(val, _.trim) as T;
    }
  } else {
    return _.trim(val) as T;
  }
}

export default trimAll;
