<template>
  <div
    v-if="isStatusShown"
    class="book-status-container"
  >
    <div
      class="status-lamp"
      :class="lampClass"
    />
    <div class="status-text">
      {{ labelText }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { BookSharingState } from '@/constants';

export default defineComponent({
  name: 'ContactBooksStatus',
  props: {
    state: {
      type: String as PropType<BookSharingState>,
      required: false,
      default: BookSharingState.NOT_SHARED,
    },
    sharer: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    isStatusShown(): boolean {
      return this.state !== BookSharingState.NOT_SHARED;
    },
    labelText(): string {
      switch (this.state) {
        case BookSharingState.NEED_CONFIRMATION:
          return this.$t('contactBooks.needConfirmation');
        case BookSharingState.SHARED:
          return this.$t('contactBooks.sharedBy', { name: this.sharer });
        case BookSharingState.DELETED_FROM_SOURCE:
          return this.$t('contactBooks.deletedFromSource');
        case BookSharingState.CANCELED:
          return this.$t('contactBooks.canceledFromSource');
        default:
          return '';
      }
    },
    lampClass(): string {
      switch (this.state) {
        case BookSharingState.NEED_CONFIRMATION:
        case BookSharingState.CANCELED:
          return 'is-need-confirmation';
        case BookSharingState.SHARED:
          return 'is-shared';
        case BookSharingState.DELETED_FROM_SOURCE:
          return 'is-deleted';
        default:
          return '';
      }
    },
  },
});

</script>
<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.book-status-container {
  display: flex;
  flex-direction: row;
  gap: $s-3;
  align-items: center;
  line-height: px-to-rem(14);

  .status-lamp {
    width: $s-3;
    min-width: $s-3;
    height: $s-3;
    min-height: $s-3;
    border-radius: 50%;

    &.is-need-confirmation {
      background-color: $c-warning;
    }

    &.is-shared {
      background-color: $c-primary;
    }

    &.is-deleted {
      background-color: $c-error;
    }
  }

  .status-text {
    font-size: $fs-0;
    font-weight: normal;
    line-height: px-to-rem(14);
    color: $c-neutral-5;
  }
}
</style>
