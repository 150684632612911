<template>
  <div class="identifiers flex flex-col">
    <span
      v-for="(identifier, index) in value"
      :key="index"
      class="identifier-value overflow-hidden text-ellipsis"
    >
      {{ identifier.val }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { IdentifierValue } from '@/types';

export default defineComponent({
  name: 'ContactsTableDataCellArrayOfIdentifiers',
  props: {
    value: {
      type: Array as PropType<IdentifierValue[]>,
      required: false,
      default: () => [],
    },
  },
});
</script>
