export { OrderDirection as ContactBooksOrderModes } from '@or-sdk/contacts';
import { ColumnTypes } from '@onereach/types-contacts-api';

export const SpecialCharactersRegexp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export enum ContactBooksViewModes {
  GRID = 'GRID',
  TABLE = 'TABLE',
}

export enum SchemaItemTypes {
  ARRAY_OF_OBJECTS = 'array of objects',
  ARRAY_OF_STRINGS = 'array of strings',
  ARRAY_OF_IDENTIFIERS = 'array of identifiers',
  STRING = 'string',
  IDENTIFIER_OBJECT = 'identifier object',
  MD = 'md',
  INTEGER = 'integer',
  DOUBLE ='double',
  DATE = 'date',
}

export enum ArrayOfObjectPropertyTypes {
  STRING = 'string',
  ARRAY = 'array',
}

export enum ExportFormats {
  CSV = 'csv',
  JSON = 'json',
}

export enum CompositeColumnKeys {
  NAME = 'composite_name',
}

export enum SharingState {
  WAITING = 'waiting',
  SHARED = 'shared',
  DELETED_FROM_TARGET = 'deleted from target',
  CANCELED = 'canceled',
}

export enum BookSharingState {
  NOT_SHARED = 'not shared',
  NEED_CONFIRMATION = 'need confirmation',
  SHARED = 'shared',
  DELETED_FROM_SOURCE = 'Deleted form source account',
  CANCELED = 'Canceled by owner',
}

export enum MigrationsStatus {
  UNDEFINED = 'UNDEFINED',
  IN_PENDING = 'IN_PENDING',
  RESOLVED = 'RESOLVED',
  FAILED = 'FAILED',
  TO_DO = 'TO_DO',
  NOT_STARTED = 'NOT_STARTED',
}

export const arrayItemsTypes = [
  ColumnTypes['array of strings'],
  ColumnTypes['array of identifiers'],
  ColumnTypes['array of objects'],
];
