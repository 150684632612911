import { Auth } from '@or-sdk/auth';

import config from '@/config';

const instance = new Auth({
  cookieDomain: config.COOKIE_DOMAIN,
  cookiePrefix: config.COOKIE_NAME_PREFIX_NEW,
  allowGuestLogin: Boolean(config.GUEST_LOGIN),
  userCookieName: `${config.COOKIE_NAME_PREFIX}_name`,
  userExpireCookieName: `${config.COOKIE_NAME_PREFIX}_user_expire`,
  multiUserCookieName: `${config.COOKIE_NAME_PREFIX}_multi`,
  allowIframe: Boolean(config.ALLOW_IFRAME),
  expireInLong: +`${config.COOKIE_EXPIRATION_LONG}`,
  expireInShort: +`${config.COOKIE_EXPIRATION_SHORT}`,
  sdkUrl: `${config.SDK_API_URL}`,
  authUiUrl: config.AUTH_UI_URL,
});

export default instance;
