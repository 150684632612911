<template>
  <component
    :is="component"
    v-if="!isEmpty"
    :value="value"
  />
</template>
<script lang="ts">
import { ColumnTypes } from '@onereach/types-contacts-api';
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';

import { hasOwnProperty } from '@/utils';

import ContactsTableDataCellArrayOfIdentifiers from './ContactsTableDataCellArrayOfIdentifiers.vue';
import ContactsTableDataCellArrayOfObjects from './ContactsTableDataCellArrayOfObjects.vue';
import ContactsTableDataCellArrayOfStrings from './ContactsTableDataCellArrayOfStrings.vue';
import ContactsTableDataCellDate from './ContactsTableDataCellDate.vue';
import ContactsTableDataCellIdentifierObject from './ContactsTableDataCellIdentifierObject.vue';
import ContactsTableDataCellNumber from './ContactsTableDataCellNumber.vue';
import ContactsTableDataCellString from './ContactsTableDataCellString.vue';

const componentsMap = {
  [ColumnTypes.string]: ContactsTableDataCellString,
  [ColumnTypes.date]: ContactsTableDataCellDate,
  [ColumnTypes.double]: ContactsTableDataCellNumber,
  [ColumnTypes.md]: ContactsTableDataCellString,
  [ColumnTypes.integer]: ContactsTableDataCellNumber,
  [ColumnTypes['identifier object']]: ContactsTableDataCellIdentifierObject,
  [ColumnTypes['array of strings']]: ContactsTableDataCellArrayOfStrings,
  [ColumnTypes['array of objects']]: ContactsTableDataCellArrayOfObjects,
  [ColumnTypes['array of identifiers']]: ContactsTableDataCellArrayOfIdentifiers,
};

export default defineComponent({
  name: 'ContactsTableDataCell',
  props: {
    type: {
      type: String as PropType<ColumnTypes>,
      required: false,
      default: null,
    },
    value: {
      type: [String, Number, Date, Object, Array],
      required: false,
      default: null,
    },
  },
  setup() {
    return {
      componentsMap,
    };
  },
  computed: {
    isEmpty(): boolean {
      return !this.value && _.isEmpty(this.value);
    },
    component(): any {
      return hasOwnProperty(this.componentsMap, this.type) ? this.componentsMap[this.type] : null;
    },
  },
});
</script>
