<template>
  <div class="contact-form-field-array-of-objects flex flex-col gap-md+ md:gap-md">
    <contact-form-field-object-array-item
      v-for="(object, index) in localData"
      :key="index"
      :properties="fieldSchema.properties"
      :singular-label="fieldSchema.singularLabel"
      :index="index"
      :item="object"
      @delete:item="handleDelete"
    />
    <or-button
      class="add-object self-start"
      data-test="add-object-button"
      variant="link"
      @click="handleAddObject"
    >
      <or-icon icon="add" />
      {{ $t('contacts.contactsForm.addItem', {singularLabel: fieldSchema.singularLabel }) }}
    </or-button>
  </div>
</template>
<script lang="ts">
import { OrIconV3 as OrIcon, OrButtonV3 as OrButton } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';

import { ArrayOfObjectPropertyTypes } from '@/constants';
import { ObjectValue, SchemaItem } from '@/types';
import { getDefaultObjectValue, isPropertyEmpty } from '@/utils';

import { ContactFormFieldObjectArrayItem } from './ContactFormFieldArrayItem';

export default defineComponent({
  name: 'ContactFormFieldArrayOfObjects',
  components: {
    OrIcon,
    OrButton,
    ContactFormFieldObjectArrayItem,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: Array as PropType<ObjectValue[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['update:data'],
  setup() {
    return {
      ArrayOfObjectPropertyTypes,
    };
  },
  data() {
    return {
      localData: _.cloneDeep(this.data),
    };
  },
  watch: {
    localData: {
      handler(val) {
        const notEmptyData = _.filter(val, _.negate(isPropertyEmpty));
        this.$emit('update:data', notEmptyData);
      },
      deep: true,
    },
  },
  methods: {
    handleAddObject(): void {
      if (this.fieldSchema.properties) {
        this.localData.push(getDefaultObjectValue(this.fieldSchema.properties));
      }
    },
    handleDelete(index: number): void {
      this.localData?.splice(index, 1);
    },
  },
});
</script>
