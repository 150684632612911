<template>
  <div>{{ value.val }}</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Contact, IdentifierValue } from '@/types';
import { getDefaultIdentifier } from '@/utils';

export default defineComponent({
  name: 'ContactsTableDataCellIdentifierObject',
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    value(): IdentifierValue {
      return (this.contact[this.columnKey] ?? getDefaultIdentifier()) as IdentifierValue;
    },
  },
});
</script>
