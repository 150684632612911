import { ContactPropertiesToShow } from '@/types';
import _ from 'lodash';
import { isPropertyEmpty } from './index';
import { ContactResponseDto } from '@onereach/types-contacts-api';

function getContactPropertiesToShow(contacts: ContactResponseDto[]): ContactPropertiesToShow {
  return _.reduce(contacts, (result, contact) => {
    _.each(contact.fieldValues, (value) => { if (!isPropertyEmpty(value.value)) result[value.schemaId] = true; });
    return result;
  }, { contactId: true } as ContactPropertiesToShow);
}

export default getContactPropertiesToShow;
