import { PiniaPlugin, PiniaPluginContext } from 'pinia';
import { computed, ref } from 'vue';

import { LoadingPluginConfig } from './types';

function LoadingHandlerPiniaPlugin(config: LoadingPluginConfig = {}): PiniaPlugin {
  const {
    ignore = [],
  } = config;

  return ({ store }: PiniaPluginContext) => {
    if (ignore.includes(store.$id)) return;

    const activeLoaders = ref<string[]>([]);
    const isLoading = computed(() => activeLoaders.value.length > 0);

    store.$onAction(({ name, after, onError }) => {
      activeLoaders.value.push(name);

      const tryRemoveLoader = () => {
        const index = activeLoaders.value.indexOf(name);
        if (index !== -1) {
          activeLoaders.value.splice(index, 1);
        }
      };

      after(tryRemoveLoader);
      onError(tryRemoveLoader);
    });

    return {
      isLoading,
      activeLoaders,
    };
  };
}

export default LoadingHandlerPiniaPlugin;
