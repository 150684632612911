<template>
  <div
    class="contact-view-field-array-of-strings flex flex-row flex-wrap gap-xs pt-xs md:pt-sm pb-md"
    data-test="contact-view-field-array-of-strings"
  >
    <or-tag
      v-for="(name, index) of data"
      :key="index"
      :can-delete="false"
    >
      {{ name }}
    </or-tag>
  </div>
</template>
<script lang="ts">
import { OrTagV3 as OrTag } from '@onereach/ui-components';
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactViewFieldArrayOfStrings',
  components: {
    OrTag,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
});
</script>
