<template>
  <div
    v-if="alwaysShow || !isEmpty"
    class="contact-view-field"
  >
    <div class="contact-view-field-title">
      {{ fieldSchema.label }}
    </div>
    <div class="contact-view-field-content">
      <component
        :is="component"
        v-if="isComponentImplemented"
        :field-schema="fieldSchema"
        :data="data || undefined"
      />
      <div v-else>
        {{ fieldSchema.typeOf }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { SchemaItem } from '@/types';
import { SchemaItemTypes } from '@/constants';
import { hasOwnProperty, isPropertyEmpty } from '@/utils';
import ContactsViewFieldString from './ContactsViewFieldString.vue';
import ContactsViewFieldDate from './ContactsViewFieldDate.vue';
import ContactsViewFieldArrayOfStrings from './ContactsViewFieldArrayOfStrings.vue';
import ContactsViewFieldArrayOfObjects from './ContactsViewFieldArrayOfObjects.vue';
import ContactsViewFieldArrayOfIdentifiers from './ContactsViewFieldArrayOfIdentifiers.vue';
import ContactsViewFieldIdentifierObject from './ContactsViewFieldIdentifierObject.vue';
import ContactsViewFieldMD from './ContactsViewFieldMD.vue';

const ViewComponents = {
  [SchemaItemTypes.STRING]: ContactsViewFieldString,
  [SchemaItemTypes.DATE]: ContactsViewFieldDate,
  [SchemaItemTypes.DOUBLE]: ContactsViewFieldString,
  [SchemaItemTypes.MD]: ContactsViewFieldMD,
  [SchemaItemTypes.INTEGER]: ContactsViewFieldString,
  [SchemaItemTypes.ARRAY_OF_STRINGS]: ContactsViewFieldArrayOfStrings,
  [SchemaItemTypes.ARRAY_OF_OBJECTS]: ContactsViewFieldArrayOfObjects,
  [SchemaItemTypes.ARRAY_OF_IDENTIFIERS]: ContactsViewFieldArrayOfIdentifiers,
  [SchemaItemTypes.IDENTIFIER_OBJECT]: ContactsViewFieldIdentifierObject,
};

export default defineComponent({
  name: 'ContactsViewField',
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: [Object, String, Number],
      required: false,
      default: () => undefined,
    },
    alwaysShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      ViewComponents,
    };
  },
  computed: {
    isEmpty(): boolean {
      return isPropertyEmpty(this.data);
    },
    isComponentImplemented(): boolean {
      return this.fieldSchema.typeOf in ViewComponents;
    },
    component(): any {
      return hasOwnProperty(ViewComponents, this.fieldSchema.typeOf)
        ? ViewComponents[this.fieldSchema.typeOf]
        : null;
    },
  },
});

</script>

<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.contact-view-field {
  &:not(:last-child) {
    border-bottom: 1px solid $c-neutral-2;
  }

  .contact-view-field-title {
    padding: 0 0 $s-4 0;
    font-size: $fs-1;
    font-weight: bold;
  }
}
</style>
