<template>
  <OrInputV3
    v-model.number="localData"
    :label="fieldSchema.label"
    :placeholder="$t('contacts.contactsForm.enterFieldValue', { name: fieldSchema.label })"
    type="number"
    :step-value="0.01"
  />
</template>
<script lang="ts">
import { OrInputV3 } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactFormFieldDouble',
  components: {
    OrInputV3,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  emits: ['update:data'],
  setup(props, { emit }) {
    const localData = useVModel(props, 'data', emit);
    return {
      localData,
    };
  },
});
</script>
