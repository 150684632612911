<template>
  <div class="contact-form-field">
    <div
      v-if="showTitle"
      class="contact-form-field-title"
    >
      {{ fieldSchema.label }}
    </div>
    <div class="contact-form-field-content">
      <component
        :is="component"
        v-if="isComponentImplemented"
        :field-schema="fieldSchema"
        :data="data"
        @update:data="$emit('update', $event)"
      />
      <div v-else>
        {{ fieldSchema.typeOf }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';

import { SchemaItemTypes } from '@/constants';
import { SchemaItem } from '@/types';
import { hasOwnProperty } from '@/utils';

import ContactFormFieldArrayOfIdentifiers from './ContactFormFieldArrayOfIdentifiers.vue';
import ContactFormFieldArrayOfObjects from './ContactFormFieldArrayOfObjects.vue';
import ContactFormFieldArrayOfStrings from './ContactFormFieldArrayOfStrings.vue';
import ContactFormFieldDate from './ContactFormFieldDate.vue';
import ContactFormFieldDouble from './ContactFormFieldDouble.vue';
import ContactFormFieldIdentifierObject from './ContactFormFieldIdentifierObject.vue';
import ContactFormFieldInteger from './ContactFormFieldInteger.vue';
import ContactFormFieldMD from './ContactFormFieldMD.vue';
import ContactFormFieldString from './ContactFormFieldString.vue';

const FieldComponents = {
  [SchemaItemTypes.STRING]: ContactFormFieldString,
  [SchemaItemTypes.DATE]: ContactFormFieldDate,
  [SchemaItemTypes.DOUBLE]: ContactFormFieldDouble,
  [SchemaItemTypes.MD]: ContactFormFieldMD,
  [SchemaItemTypes.INTEGER]: ContactFormFieldInteger,
  [SchemaItemTypes.ARRAY_OF_STRINGS]: ContactFormFieldArrayOfStrings,
  [SchemaItemTypes.ARRAY_OF_OBJECTS]: ContactFormFieldArrayOfObjects,
  [SchemaItemTypes.ARRAY_OF_IDENTIFIERS]: ContactFormFieldArrayOfIdentifiers,
  [SchemaItemTypes.IDENTIFIER_OBJECT]: ContactFormFieldIdentifierObject,
};

export default defineComponent({
  name: 'ContactFormField',
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: [Object, String, Number],
      required: false,
      default: () => undefined,
    },
  },
  emits: ['update'],
  setup(props) {
    const isComponentImplemented = computed(() => props.fieldSchema.typeOf in FieldComponents);

    const component = computed(() => hasOwnProperty(FieldComponents, props.fieldSchema.typeOf)
      ? FieldComponents[props.fieldSchema.typeOf]
      : null,
    );

    const showTitle = computed(() => ![
      SchemaItemTypes.STRING,
      SchemaItemTypes.DOUBLE,
      SchemaItemTypes.INTEGER,
      SchemaItemTypes.DATE,
    ].includes(props.fieldSchema.typeOf));

    return {
      component,
      FieldComponents,
      isComponentImplemented,
      showTitle,
    };
  },
});

</script>

<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.contact-form-field {
  .contact-form-field-title {
    padding: 0 0 $s-4 0;
    font-size: px-to-rem(14);
    font-weight: bold;
  }
}
</style>
