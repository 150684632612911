<template>
  <SettingsContainer>
    <div class="layout-row h-full w-full overflow-hidden">
      <OrSidebarV3
        collapsible
        resizeable
        :width="255"
        placement="left"
      >
        <SettingsMenu />
      </OrSidebarV3>
      <div class="layout-column h-full grow p-xl overflow-hidden">
        <slot />
      </div>
    </div>
  </SettingsContainer>
</template>
<script lang="ts">
import { OrSidebarV3 } from '@onereach/ui-components';
import { defineComponent } from 'vue';

import SettingsMenu from '@/components/Settings/SettingsMenu/SettingsMenu.vue';
import SettingsContainer from '@/containers/Settings/SettingsContainer.vue';

export default defineComponent({
  name: 'SettingsLayout',
  components: {
    OrSidebarV3,
    SettingsMenu,
    SettingsContainer,
  },
  setup() {
    return {
    };
  },
});
</script>
