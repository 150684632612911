<template>
  <div class="profile-container">
    <or-avatar
      :src="profileImage"
      class="picture"
      size="large"
    />
    <div class="name">
      {{ name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { OrAvatar } from '@onereach/ui-components-legacy';

export default defineComponent({
  name: 'ContactViewModalProfileInfo',
  components: {
    OrAvatar,
  },
  props: {
    firstName: {
      type: String,
      required: false,
      default: () => null,
    },
    lastName: {
      type: String,
      required: false,
      default: () => null,
    },
    profileImage: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  computed: {
    hasName(): boolean {
      return !!this.firstName || !!this.lastName;
    },
    name(): string {
      return this.hasName ?
        `${this.firstName ?? ''} ${this.lastName ?? ''}` :
        this.$t('contacts.noName');
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.profile-container {
  display: flex;
  flex-direction: row;
  gap: $s-4;
  min-width: 0;
}

.picture {
  flex-shrink: 0;
}

.name {
  align-self: center;
  overflow: hidden;
  font-size: $fs-2;
  font-weight: bold;
  text-overflow: ellipsis;
}
</style>
