<template>
  <div
    v-if="alwaysShow || !isEmpty"
    class="contact-view-field text-ellipsis theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 !border-b-1 last-of-type:!border-b-0"
  >
    <div
      class="contact-view-field-title truncate typography-body-2-bold"
      data-test="contact-view-field-title"
    >
      {{ fieldSchema.label }}
    </div>
    <div class="contact-view-field-content typography-body-2-regular">
      <component
        :is="component"
        v-if="isComponentImplemented"
        :field-schema="oldFieldSchema"
        :data="data || undefined"
      />
      <div
        v-else
        class="contact-view-field-title truncate typography-body-2-bold"
      >
        {{ fieldSchema.type }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ColumnTypes, ContactBookFieldSchemaDto } from '@onereach/types-contacts-api';
import { defineComponent, PropType } from 'vue';

import { SchemaItemTypes } from '@/constants';
import { SchemaItem } from '@/types';
import { hasOwnProperty, isPropertyEmpty } from '@/utils';

import ContactsViewFieldArrayOfIdentifiers from './ContactsViewFieldArrayOfIdentifiers.vue';
import ContactsViewFieldArrayOfObjects from './ContactsViewFieldArrayOfObjects.vue';
import ContactsViewFieldArrayOfStrings from './ContactsViewFieldArrayOfStrings.vue';
import ContactsViewFieldDate from './ContactsViewFieldDate.vue';
import ContactsViewFieldIdentifierObject from './ContactsViewFieldIdentifierObject.vue';
import ContactsViewFieldMD from './ContactsViewFieldMD.vue';
import ContactsViewFieldString from './ContactsViewFieldString.vue';

const ViewComponents = {
  [ColumnTypes.string]: ContactsViewFieldString,
  [ColumnTypes.date]: ContactsViewFieldDate,
  [ColumnTypes.double]: ContactsViewFieldString,
  [ColumnTypes.md]: ContactsViewFieldMD,
  [ColumnTypes.integer]: ContactsViewFieldString,
  [ColumnTypes['array of strings']]: ContactsViewFieldArrayOfStrings,
  [ColumnTypes['array of objects']]: ContactsViewFieldArrayOfObjects,
  [ColumnTypes['array of identifiers']]: ContactsViewFieldArrayOfIdentifiers,
  [ColumnTypes['identifier object']]: ContactsViewFieldIdentifierObject,
};

export default defineComponent({
  name: 'ContactsViewField',
  props: {
    fieldSchema: {
      type: Object as PropType<ContactBookFieldSchemaDto>,
      required: true,
    },
    data: {
      type: [Object, String, Number],
      required: false,
      default: () => undefined,
    },
    alwaysShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      ViewComponents,
    };
  },
  computed: {
    oldFieldSchema(): SchemaItem { //TODO: remove after migration to V2 API
      return {
        visibility: true,
        label: this.fieldSchema.label,
        singularLabel: this.fieldSchema.singular_label,
        order: 0,
        properties: this.fieldSchema.properties,
        triggers: [],
        typeOf: this.fieldSchema.type as unknown as SchemaItemTypes,
      };
    },
    isEmpty(): boolean {
      return isPropertyEmpty(this.data);
    },
    isComponentImplemented(): boolean {
      return this.fieldSchema.type in ViewComponents;
    },
    component(): any {
      return hasOwnProperty(ViewComponents, this.fieldSchema.type)
        ? ViewComponents[this.fieldSchema.type]
        : null;
    },
  },
});

</script>
