<template>
  <div class="flex flex-row items-center overflow-hidden gap-xs">
    <div
      ref="container"
      class="relative flex flex-row grow items-center overflow-hidden gap-xs whitespace-nowrap"
    >
      <or-tag
        v-for="tag in tags"
        :key="tag"
        class="max-w-full"
      >
        {{ tag }}
      </or-tag>
    </div>
    <template v-if="outerItems?.length">
      <or-button
        ref="button"
        class="!typography-caption-regular text-on-background dark:text-on-background-dark"
        color="inherit"
        variant="link"
        @click="popover.open()"
      >
        {{ moreText }}
      </or-button>
      <or-popover
        ref="popover"
        :trigger="button?.root"
        variant="popover"
      >
        <div
          class="max-w-[192px] max-h-[min(346px,50vh-26px)] p-md flex flex-row flex-wrap items-center overflow-auto gap-xs whitespace-nowrap"
        >
          <or-tag
            v-for="tag in hiddenTags"
            :key="tag"
            class="max-w-full"
          >
            {{ tag }}
          </or-tag>
        </div>
      </or-popover>
    </template>
  </div>
</template>

<script lang="ts">
import {
  OrTagV3 as OrTag,
  OrButtonV3 as OrButton,
  OrPopoverV3 as OrPopover,
  useOverflow,
} from '@onereach/ui-components';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'OrTagsContainer',
  components: {
    OrTag,
    OrButton,
    OrPopover,
  },
  props: {
    tags: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup() {
    const container = ref<HTMLElement>();
    const button = ref<InstanceType<typeof OrButton>>();
    const popover = ref<InstanceType<typeof OrPopover>>();

    const { innerItems, outerItems } = useOverflow(container);

    return {
      container,
      button,
      popover,
      innerItems,
      outerItems,
    };
  },
  computed: {
    moreText(): string {
      return `+${this.outerItems.length}`;
    },
    hiddenTags(): string[] {
      return this.tags.slice(this.innerItems?.length);
    },
  },
});
</script>
