import {
  CreateFilterDto,
  FilterListParamsDto,
  FilterResponseDto, UpdateFilterDto,
} from '@onereach/types-contacts-api';
import { defineStore } from 'pinia';

import { ContactsApiV2 } from '@/api';

const useFiltersStore = defineStore('filtersV2', {
  state: () => ({
    globalFilters: [] as FilterResponseDto[],
    collections: {} as { [bookId: string]: FilterResponseDto[]; },
    filters: {} as { [id: string]: FilterResponseDto; },
  }),

  getters: {
    getFiltersByBookId: (state) => {
      return (bookId: string) => {
        return state.collections[bookId]
          ? state.collections[bookId]
          : [] as FilterResponseDto[];
      };
    },

    getGlobalFilters: (state) => {
      return () => {
        return state.globalFilters as FilterResponseDto[];
      };
    },

    getFilterById: (state) => (filterId: string): FilterResponseDto => state.filters[filterId],
  },

  actions: {
    async getFilters(params: FilterListParamsDto): Promise<void> {
      const data = await ContactsApiV2.filterApi.listFilters(params);
      if (params.contact_book) {
        this.collections[params.contact_book] = data.items;
      } else {
        this.globalFilters = data.items;
      }
      data.items.forEach(filter => this.filters[filter.id] = filter);
    },

    async getFilter(filterId: string): Promise<void> {
      this.filters[filterId] = await ContactsApiV2.filterApi.getFilter(filterId);
    },

    async addFilter(data: CreateFilterDto): Promise<FilterResponseDto> {
      const filter = await ContactsApiV2.filterApi.createFilter(data);
      this.filters[filter.id] = filter;
      return filter;
    },

    async updateFilter(filterId: string, data: UpdateFilterDto): Promise<void> {
      const filter = await ContactsApiV2.filterApi.updateFilter(filterId, data);
      this.filters[filter.id] = filter;
    },

    async deleteFilter(filterId: string): Promise<void> {
      await ContactsApiV2.filterApi.deleteFilter(filterId);
      delete this.filters[filterId];
    },
  },
});

export default useFiltersStore;
