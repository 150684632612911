<template>
  <div class="contact-view-field-identifier-object flex flex-col gap-sm pb-md pt-sm md:pt-sm+">
    <div class="text text-ellipsis overflow-hidden">
      {{ data.val }}
    </div>
    <div class="chips-container max-w-full flex flex-row flex-wrap gap-xs">
      <or-tag
        v-for="type of data.type"
        :key="type"
      >
        {{ type }}
      </or-tag>
    </div>
  </div>
</template>
<script lang="ts">
import { OrTagV3 as OrTag } from '@onereach/ui-components';
import { defineComponent, PropType } from 'vue';

import { IdentifierValue, SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactsViewFieldIdentifierObject',
  components: {
    OrTag,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: Object as PropType<IdentifierValue>,
      required: false,
      default: () => ({
        val: '',
        type: [],
      }),
    },
  },
});
</script>
