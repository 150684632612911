import { Contact, ContactPropertiesToShow } from '@/types';
import _ from 'lodash';
import { isPropertyEmpty } from './index';

function getContactPropertiesToShow(contacts: Contact[]): ContactPropertiesToShow {
  return _.reduce(contacts, (result, contact) => {
    _.each(contact, (value, key) => { if (!isPropertyEmpty(value)) result[key] = true; });
    return result;
  }, { contactId: true } as ContactPropertiesToShow);
}

export default getContactPropertiesToShow;
