import { ContactsApi } from '@onereach/sdk';
import _ from 'lodash';

import config from '@/config';
import { useAuth } from '@/stores';

const instance = new ContactsApi({
  userTokenFactory: () => {
    return `USER ${_.get(useAuth(), 'reactiveToken')}`;
  },
  httpGatewayUrl: config.GATEWAY_URL,
  providersAccountId: config.PROVIDERS_ACCOUNT_ID,
});

export default instance;

