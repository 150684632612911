<template>
  <div class="contact-form-field-identifier-item flex flex-col gap-md+ md:gap-md pb-md">
    <OrInputV3
      v-model="localData.val"
      :placeholder="$t('contacts.contactsForm.enterFieldValue', { name: fieldSchema.label })"
    />
    <OrTagInputV3
      v-model="localData.type"
      :label="$t('contacts.contactsForm.type')"
      :placeholder="$t('contacts.contactsForm.enterFieldType', { name: fieldSchema.label })"
    />
  </div>
</template>

<script lang="ts">
import { OrTagInputV3, OrInputV3 } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue';

import { IdentifierValue, SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactFormFieldIdentifierObject',
  components: {
    OrInputV3,
    OrTagInputV3,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: Object as PropType<IdentifierValue>,
      required: false,
      default: () => ({
        val: '',
        type: [],
      }),
    },
  },
  emits: ['update:data'],
  setup(props, { emit }) {
    const localData = useVModel(props, 'data', emit, { deep: true });
    return {
      localData,
    };
  },
});
</script>
