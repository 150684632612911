<template>
  <div class="array-of-objects flex flex-col">
    <span
      v-for="val in convertedValue"
      :key="val"
      class="overflow-hidden text-ellipsis whitespace-normal line-clamp-2"
    >
      {{ val }}
    </span>
  </div>
</template>
<script lang="ts">
import { join, toPairs, isEmpty } from 'lodash';
import { defineComponent, PropType } from 'vue';

import { ObjectValue } from '@/types';

export default defineComponent({
  name: 'ContactsTableDataCellArrayOfObjects',
  props: {
    value: {
      type: Array as PropType<ObjectValue[]>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    convertedValue(): string[] {
      return this.value?.map((objValue) => join(
        toPairs(objValue)
          .filter(([key]) => key !== 'type')
          .map(([, value]) => Array.isArray(value) ? join(value, ', ') : value)
          .filter(value => !isEmpty(value)),
        ', ',
      ),
      ) ?? [];
    },
  },
});
</script>
