<template>
  <or-expansion-panel
    class="contact-form-field-object-item"
    :label="itemName"
    variant="card"
    is-open
  >
    <template #addon>
      <or-icon-button
        class="delete-button"
        color="default"
        icon="delete"
        @click="handleDelete"
      />
    </template>
    <div class="content flex flex-col gap-md+ md:gap-md">
      <div
        v-for="(type, name) in properties"
        :key="name"
        class="contact-form-field-object-item-prop"
      >
        <or-input
          v-if="type === ArrayOfObjectPropertyTypes.STRING"
          v-model="itemModel[name]"
          :label="getLabel(name)"
          :placeholder="$t('contacts.contactsForm.enterFieldValue', { name: getLabel(name) })"
        />
        <OrTagInputV3
          v-if="type === ArrayOfObjectPropertyTypes.ARRAY"
          v-model="itemModel[name]"
          overflow="hidden"
          :label="getLabel(name)"
          :placeholder="$t('contacts.contactsForm.enterFieldValue', { name: getLabel(name) })"
        />
      </div>
    </div>
  </or-expansion-panel>
</template>
<script lang="ts">
import {
  OrTagInputV3,
  OrInputV3 as OrInput,
  OrIconButtonV3 as OrIconButton,
  OrExpansionPanelV3 as OrExpansionPanel,
} from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';

import { ArrayOfObjectPropertyTypes } from '@/constants';
import { ObjectValue, Properties } from '@/types';
import { getDefaultObjectValue } from '@/utils';

export default defineComponent({
  name: 'ContactFormFieldObjectArrayItem',
  components: {
    OrInput,
    OrIconButton,
    OrExpansionPanel,
    OrTagInputV3,
  },
  props: {
    properties: {
      type: Object as PropType<Properties>,
      required: true,
    },
    singularLabel: {
      type: String,
      required: false,
      default: 'Value',
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    item: {
      type: Object as PropType<ObjectValue>,
      required: false,
      default: () => {},
    },
    capitalizeProperties: {
      type: Boolean,
      required: false,
      default: false,
    },
    nameToReplaceWithSingular: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['update:item', 'delete:item'],
  setup(props, { emit }) {
    const itemModel = useVModel(props, 'item', emit);

    _.defaults(itemModel.value, getDefaultObjectValue(props.properties));

    return {
      ArrayOfObjectPropertyTypes,
      itemModel,
    };
  },
  computed: {
    itemName(): string {
      return `${this.singularLabel} ${this.index + 1}`;
    },
  },
  methods: {
    getLabel(name: string): string {
      return this.nameToReplaceWithSingular === name ? this.singularLabel :
        this.capitalizeProperties ? _.capitalize(name) : name;
    },
    handleDelete(): void {
      this.$emit('delete:item', this.index);
    },
  },
});
</script>
