<template>
  <div>
    <or-date-time-picker
      v-model="localData"
      :label="fieldSchema.label"
    />
  </div>
</template>
<script lang="ts">
import { OrDateTimePickerV3 as OrDateTimePicker } from '@onereach/ui-components';
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactFormFieldDate',
  components: {
    OrDateTimePicker,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: [String, Date],
      required: false,
      default: null,
    },
  },
  emits: ['update:data'],
  data() {
    return {
      localData: this.data ? new Date(this.data) : null,
    };
  },
  watch: {
    data(val) {
      const newDate = new Date(val);
      if (+(this.localData ?? 0) != +newDate) this.localData = newDate;
    },
    localData(val) {
      if (+val != +(new Date(this.data))) this.$emit('update:data', val || null);
    },
  },
});
</script>
<style lang="scss" scoped>
.or-date-time-picker {
  width: 100% !important;
}
</style>
