import { defineStore } from 'pinia';
import { AddRuleGroupDTO, ContactRuleGroup, DeleteRuleGroupDTO, GetRuleGroupsDTO, UpdateRuleGroupDTO } from '@/types';
import { ContactsApi } from '@/api';
import _ from 'lodash';

export const useContactsRuleGroupsStore = defineStore('ruleGroups', {
  state: () => ({
    items: [] as ContactRuleGroup[],
  }),
  getters: {
    getRuleGroupByName: (state) => {
      return (name: string) => {
        return _.find(state.items, { name });
      };
    },
    getRuleGroupByGroupId: (state) => {
      return (groupId: string) => {
        return _.find(state.items, { groupId });
      };
    },
  },
  actions: {
    async getRuleGroups(payload: GetRuleGroupsDTO = {}): Promise<void> {
      const {
        from = 0,
        searchStr: searchPhrase,
      } = payload;
      const { data: items } = await ContactsApi.getContactRuleGroupsList({
        from,
        searchPhrase,
      }) as { data: ContactRuleGroup[]; };

      this.items = items;
    },
    async addRuleGroup(payload: AddRuleGroupDTO): Promise<void> {
      await ContactsApi.addContactRuleGroup(payload);
    },
    async deleteRuleGroup({ groupId }: DeleteRuleGroupDTO): Promise<void> {
      await ContactsApi.deleteContactRuleGroup({ groupId });
    },
    async getRuleGroup(groupId: string): Promise<void> {
      const data = await ContactsApi.getContactRuleGroup({ groupId }) as ContactRuleGroup;
      const index = _.findIndex(this.items, { groupId: data.groupId });
      if (index > -1) {
        this.items[index] = data;
      } else {
        this.items.push(data);
      }
    },
    async updateRuleGroup(ruleGroup: UpdateRuleGroupDTO): Promise<void> {
      await ContactsApi.updateContactRuleGroup(ruleGroup);
      await this.getRuleGroup(ruleGroup.groupId);
    },
  },
});

export default useContactsRuleGroupsStore;
