<template>
  <div class="array-of-strings">
    <or-tags-container
      :tags="value"
      @click.stop
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import OrTagsContainer from '@/components/OrTagsContainer.vue';

export default defineComponent({
  name: 'ContactsTableDataCellArrayOfStrings',
  components: {
    OrTagsContainer,
  },
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
});
</script>
