<template>
  <div class="array-of-objects">
    <span
      v-for="val in convertedValue"
      :key="val"
    >
      {{ val }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Contact, ObjectValue } from '@/types';
import _ from 'lodash';

export default defineComponent({
  name: 'ContactsTableDataCellArrayOfObjects',
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    value(): ObjectValue[] | undefined {
      return this.contact[this.columnKey] as ObjectValue[] | undefined;
    },
    convertedValue(): string[] {
      return this.value?.map(objValue =>
        _.join(_.toPairs(objValue).filter(([key]) => key !== 'type')
          .map(([, value]) => value).filter(value => !_.isEmpty(value)), ', ')) ?? [];
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.array-of-objects {
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: auto;

  & > * {
    flex-shrink: 0;
    max-width: 100%;
  }
}
</style>
