<template>
  <div class="contact-form-field-array-of-strings">
    <OrTagInputV3
      v-model="localData"
      overflow="wrap"
      :label="fieldSchema.label"
      :placeholder="$t('contacts.contactsForm.enterFieldValue', { name: fieldSchema.singularLabel ?? fieldSchema.label })"
    />
  </div>
</template>
<script lang="ts">
import { OrTagInputV3 } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactFormFieldArrayOfStrings',
  components: {
    OrTagInputV3,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['update:data'],
  setup(props, { emit }) {
    const localData = useVModel(props, 'data', emit);
    return {
      localData,
    };
  },
});
</script>
