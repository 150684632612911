// Modified from: https://stackoverflow.com/questions/42754270/

class RethrownError extends Error {
  constructor(message: string, public readonly originalError: Error, skipOwnStack = false) {
    super(message);
    this.name = this.constructor.name;
    const messageLines = (this.message.match(/\n/g) || []).length + 1;
    this.stack = skipOwnStack ?
      originalError.stack :
      ((this.stack ?? '').split('\n').slice(0, messageLines + 1).join('\n') + '\n' + originalError.stack);
    Object.setPrototypeOf(this, RethrownError.prototype);
  }
}

export default RethrownError;
