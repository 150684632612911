<template>
  <div class="contact-form-field-array-of-identifiers flex flex-col gap-md+ md:gap-md">
    <contact-form-field-object-array-item
      v-for="(identifier, index) in localData"
      :key="index"
      :properties="properties"
      :singular-label="fieldSchema.singularLabel"
      :index="index"
      :item="identifier"
      capitalize-properties
      name-to-replace-with-singular="val"
      @delete:item="handleDelete"
    />
    <or-button
      class="add-identifier self-start"
      data-test="add-identifier-button"
      variant="link"
      @click="handleAddIdentifier"
    >
      <or-icon icon="add" />
      {{ $t('contacts.contactsForm.addItem', {singularLabel: fieldSchema.singularLabel }) }}
    </or-button>
  </div>
</template>
<script lang="ts">
import { OrIconV3 as OrIcon, OrButtonV3 as OrButton } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';

import { IdentifierValue, SchemaItem } from '@/types';
import { getDefaultIdentifier, isPropertyEmpty } from '@/utils';

import { ContactFormFieldObjectArrayItem } from './ContactFormFieldArrayItem';

export default defineComponent({
  name: 'ContactFormFieldArrayOfIdentifiers',
  components: {
    OrIcon,
    OrButton,
    ContactFormFieldObjectArrayItem,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: Array as PropType<IdentifierValue[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['update:data'],
  data() {
    return {
      localData: _.cloneDeep(this.data),
    };
  },
  computed: {
    properties() {
      return _.isEmpty(this.fieldSchema.properties) ? {
        val: 'string',
        type: 'array',
      } : this.fieldSchema.properties;
    },
  },
  watch: {
    localData: {
      handler(val) {
        const notEmptyData = _.filter(val, _.negate(isPropertyEmpty));
        this.$emit('update:data', notEmptyData);
      },
      deep: true,
    },
  },
  methods: {
    handleAddIdentifier(): void {
      this.localData.push(getDefaultIdentifier());
    },
    handleDelete(index: number): void {
      this.localData?.splice(index, 1);
    },
  },
});
</script>
