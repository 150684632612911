<template>
  <div class="text text-ellipsis overflow-hidden pb-md+ pt-sm md:pt-sm+">
    {{ data }}
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactViewFieldMD',
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: String,
      required: false,
      default: () => '',
    },
  },
});
</script>
