<template>
  <OrTextareaV3
    v-model="localData"
    :label="fieldSchema.label"
    :placeholder="$t('contacts.contactsForm.enterFieldValue', { name: fieldSchema.label })"
  />
</template>
<script lang="ts">
import { OrTextareaV3 } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';

export default defineComponent({
  name: 'ContactFormFieldMD',
  components: {
    OrTextareaV3,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:data'],
  setup(props, { emit }) {
    const localData = useVModel(props, 'data', emit);
    return {
      localData,
    };
  },
});
</script>
