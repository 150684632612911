<template>
  <div
    class="contacts-tabs h-full w-full"
  >
    <OrTabsV3
      v-if="isAnyTabSelected"
      v-model="selectedTab"
      :items="tabsItems"
      :variant="isMobile ? 'fitted' : 'default'"
      :class="tabsStyles"
    >
      <template #[selectedTab]>
        <div
          class="layout-column overflow-y-auto overflow-x-hidden h-full"
          :class="!isCustomFullPage ? 'max-w-[min(1376px,calc(100%-theme(spacing.3xl)))] w-full mx-auto':''"
        >
          <slot />
        </div>
      </template>
    </OrTabsV3>
    <slot v-else />
  </div>
</template>

<script lang="ts">
import { OrTabsV3, useResponsive } from '@onereach/ui-components';
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useI18n } from '@/composables';

export default defineComponent({
  name: 'RoutingTabsLayout',
  components: {
    OrTabsV3,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const { isMobile } = useResponsive();

    const selectedTab = ref('');
    const currentRouteName = computed(() => route.name as string);

    const isCustomFullPage = computed(() => ['all contacts v2', 'contacts v2'].includes(route.name as string));
    const tabsStyles = computed(() => ([
      isCustomFullPage.value ? 'full-screen-tabs' : '',
    ]));


    const tabsItems = computed(() => [
      {
        value: 'books v2 index',
        label: t('tabsItems.books'),
      },
      {
        value: 'all contacts v2',
        label: t('tabsItems.contacts'),
      },
      {
        value: 'field schemas',
        label: t('tabsItems.fields'),
      },
    ]);

    const isAnyTabSelected = computed(() => tabsItems.value.map(item => item.value).includes(currentRouteName.value));

    watch(() => currentRouteName.value, (val) => {
      selectedTab.value = val;
    }, { immediate: true });

    watch(() => selectedTab.value, (val) => {
      if (route.name === val) {
        return;
      }
      router.replace({ name: val });
    });
    return {
      currentRouteName,
      isCustomFullPage,
      tabsStyles,
      isMobile,
      tabsItems,
      selectedTab,
      isAnyTabSelected,
    };
  },
});
</script>

<style lang="scss" scoped>
.contacts-tabs:deep(.or-tabs-v3) {
  @apply min-h-[36px] max-w-[min(1376px,calc(100%-theme(spacing.3xl)))] pt-md md:pt-xl my-0 mx-auto w-full;
}
</style>
