import _ from 'lodash';
import { defineStore } from 'pinia';

import { ContactsApi, ContactsSettingsApi } from '@/api';
import { TranslatableError } from '@/errors';
import {
  GetSharedUsersDTO,
  PatchedUserInfo, StopSharingBookDTO,
  UpdatedUserInfo,
  UserInfo,
  UserPatch,
  UserUpdate,
} from '@/types';
import { getStoreIdWithoutVersion } from '@/utils';

export const useAccessSettingsStore = defineStore('accessSettings', {
  state: () => ({
    settings: {} as { [bookName: string]: UserInfo[]; },
    sharedUsers: {} as { [bookName: string]: { [accountId: string]: UserInfo[]; }; },
  }),
  getters: {
    getUsersByBookName: (state) => {
      return (bookName: string) => {
        return state.settings[bookName];
      };
    },
    getSharedUsers: (state) => {
      return (bookName: string, accountId: string) => {
        return _.get(state.sharedUsers, `[${bookName}][${accountId}]`) as unknown as UserInfo[] | undefined;
      };
    },
    getUserById: (state) => {
      return (bookName: string, id: string) => {
        return _.find(state.settings[bookName], { id });
      };
    },
    getUserByAccountId: (state) => {
      return (bookName: string, accountId: string) => {
        return _.find(state.settings[bookName], { accountId });
      };
    },
  },
  actions: {
    async getUsers(bookName: string): Promise<void> {
      this.settings[bookName] = await ContactsApi.getUsersList({
        bookName,
      }) as UserInfo[];
    },
    async updateUserRuleGroups(payload: UserUpdate): Promise<void> {
      const updateInfo = await ContactsApi.updateUserContactRuleGroups(payload) as UpdatedUserInfo;

      const users = this.settings[updateInfo.bookName];

      const user = _.find(users, {
        accountId: updateInfo.accountId,
        id: updateInfo.userId,
      }) ??
        _.find(this.getSharedUsers(updateInfo.bookName, updateInfo.accountId), {
          accountId: updateInfo.accountId,
          id: updateInfo.userId,
        });

      if (user) _.set(user, 'data.meta.contacts.ruleGroups', updateInfo.ruleGroups);
    },
    async patchUserRuleGroups(payload: UserPatch): Promise<void> {

      const patchInfo = await ContactsApi.getUsersList(payload) as PatchedUserInfo;

      const {
        userId,
        bookName,
        userAccountId: accountId,
      } = payload as UserPatch;

      const users = this.settings[bookName];

      const user = _.find(users, {
        accountId,
        id: userId,
      }) ??
        _.find(this.getSharedUsers(bookName, accountId), {
          accountId: accountId,
          id: userId,
        });

      if (user) _.set(user, 'data.meta.contacts.ruleGroups', patchInfo.contactRuleGroups);
    },
    async getUsersListFromAnotherAccount(dto: GetSharedUsersDTO): Promise<void> {
      try {
        _.set(this.sharedUsers,
          `[${dto.name}][${dto.accountId}]`,
          await ContactsSettingsApi.getUsersListFromAnotherAccount(dto) as UserInfo[]);
      } catch (e) {
        if (e instanceof Error && e.message.includes('status code 400')) {
          _.set(this.sharedUsers,
            `[${dto.name}][${dto.accountId}]`,
            undefined);
          if ('cause' in (e as any) && _.isFunction((e as any).cause)) {
            const cause = (e as any).cause();
            const errorMessage = _.get(cause, 'response.data.error') as string | unknown;
            if (typeof errorMessage === 'string') {
              if (errorMessage.includes('not approved to share'))
                throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.notApprovedToShare`, e);
            }
          }
        }
        throw e;
      }
    },
    deleteCachedUsersListFromAnotherAccount(dto: StopSharingBookDTO): void {
      _.set(this.sharedUsers,
        `[${dto.name}][${dto.bookAccountId}]`,
        undefined);
    },
  },
});

export default useAccessSettingsStore;
