import { CreateSchemaPresetDto, SchemaPresetResponseDto, UpdateSchemaPresetDto } from '@onereach/types-contacts-api';
import _ from 'lodash';
import { defineStore } from 'pinia';

import { ContactsApiV2 } from '@/api';
import { TranslatableError } from '@/errors';
import { getStoreIdWithoutVersion } from '@/utils';


export const useSchemaPresets = defineStore('schemaPresets', {
  state: () => ({
    total: null as number | null,
    items: [] as SchemaPresetResponseDto[],
  }),
  getters: {
    getSchemaPresetById: (state) => {
      return (id: string) => {
        return _.find<SchemaPresetResponseDto>(state.items, { id });
      };
    },
  },
  actions: {
    async getSchemaPresets(): Promise<void> {
      const resp = await ContactsApiV2.schemaPresetApi.listSchemaPresets();
      this.total = resp.total;
      this.items = _.orderBy(resp.items, 'label');
    },
    async deleteSchemaPreset(id: string): Promise<void> {
      await ContactsApiV2.schemaPresetApi.deleteSchemaPreset(id, { softDelete: false });
      await this.getSchemaPresets();
    },
    _updateSchemaPreset(data: SchemaPresetResponseDto): void {
      const index = _.findIndex(this.items, { id: data.id });
      if (index > -1) {
        this.items[index] = data;
      } else {
        this.items.push(data);
      }
    },
    async createSchemaPreset(dto: CreateSchemaPresetDto): Promise<SchemaPresetResponseDto> {
      try {
        const data = await ContactsApiV2.schemaPresetApi.createSchemaPreset(dto);
        await this.getSchemaPresets();
        return data;
      } catch (e) {
        if (e instanceof Error && e.message.includes('already exist')) {
          throw new TranslatableError(
            `errors.${getStoreIdWithoutVersion(this.$id)}.schemaPresetWithThisNameAlreadyExists`,
            e,
          );
        }
        throw e;
      }
    },
    async getSchemaPreset(id: string): Promise<void> {
      const data = await ContactsApiV2.schemaPresetApi.getSchemaPreset(id);
      this._updateSchemaPreset(data);
    },
    async updateSchemaPreset(id: string, dto: UpdateSchemaPresetDto): Promise<void> {
      const data = await ContactsApiV2.schemaPresetApi.updateSchemaPreset(id, dto);
      this._updateSchemaPreset(data);
    },
  },
});

export default useSchemaPresets;
