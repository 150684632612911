import { FieldSchemaResponseDto, CreateFieldSchemaDto, UpdateFieldSchemaDto } from '@onereach/types-contacts-api';
import _ from 'lodash';
import { defineStore } from 'pinia';

import { ContactsApiV2 } from '@/api';

export const useFieldSchemasStore = defineStore('fieldSchemas', {
  state: () => ({
    items: [] as FieldSchemaResponseDto[],
    total: null as number | null,
    paginationPage: 1,
    paginationLimit: 20,
  }),
  getters: {
    getFieldSchemaById: (state) => {
      return (id: string) => {
        return _.find<FieldSchemaResponseDto>(state.items, { id });
      };
    },
    getFieldSchemaByLabel: (state) => {
      return (label: string) => {
        return _.find<FieldSchemaResponseDto>(state.items, { label });
      };
    },
  },
  actions: {
    async getFieldSchemas(): Promise<void> {
      try {
        const resp = await ContactsApiV2.fieldSchemaApi.listSchemas({
          size: this.paginationLimit,
          from: (this.paginationPage - 1) * this.paginationLimit,
        });
        this.total = resp.total;
        this.items = [...this.items, ...resp.items];
      } catch (error) {
        console.error('Error fetching field schemas:', error);
      }
    },

    async fetchAllFieldSchemas(): Promise<void> {
      this.resetStore();
      let moreItems = true;
      while (moreItems) {
        await this.getFieldSchemas();
        moreItems = this.items.length < this.total!;
        if (moreItems) {
          this.paginationPage += 1;
        }
      }
    },

    async addFieldSchema(payload: CreateFieldSchemaDto): Promise<void> {
      await ContactsApiV2.fieldSchemaApi.createSchema(payload);
      this.resetStore();
      await this.fetchAllFieldSchemas();
    },

    async deleteFieldSchema(id: string): Promise<void> {
      await ContactsApiV2.fieldSchemaApi.deleteSchema(id);
      this.resetStore();
      await this.fetchAllFieldSchemas();
    },

    _updateFieldSchema(data: FieldSchemaResponseDto): void {
      const index = _.findIndex(this.items, { id: data.id });
      if (index > -1) {
        this.items[index] = data;
      } else {
        this.items.push(data);
      }
    },
    async getFieldSchema(id: string): Promise<void> {
      const data = await ContactsApiV2.fieldSchemaApi.getSchema(id);
      this._updateFieldSchema(data);
    },

    async updateFieldSchema(id: string, dto: UpdateFieldSchemaDto): Promise<void> {
      const data = await ContactsApiV2.fieldSchemaApi.updateSchema(id, dto);
      this._updateFieldSchema(data);
    },

    resetStore(): void {
      this.items = [];
      this.paginationPage = 1;
    },
  },
});

export default useFieldSchemasStore;
