<template>
  <div
    class="contacts-table-container"
    :class="{ 'full-height': isLoading }"
  >
    <div
      class="contacts-table"
      data-test="contacts-table"
    >
      <div class="contacts-table-head">
        <div class="row head-row">
          <div class="header-cell action-select">
            <or-checkbox
              with-state
              :model-value="mainCheckboxState"
              :is-disabled="isEmpty"
              @update:modelValue="handleSelectAll"
            />
          </div>
          <div
            v-for="column in orderedColumns"
            :key="column.label"
            class="header-cell"
            data-test="header-cell"
            :style="dataCellCss[column.key]"
          >
            <contacts-table-column-header-cell
              :column-key="column.key"
              :value="column.label"
              :width="storedColumnsInfo[column.key].width"
              :can-be-resized-now="!isAnyResizing"
              :can-be-ordered="typesToOrder.includes(column.typeOf)"
              :order-mode="columnToOrderBy === column.key ? orderMode : null"
              @widthChange="onColumnHeaderWidthChange"
              @barDragStart="onColumnHeaderWidthChangeStart"
              @barDragEnd="onColumnHeaderWidthChangeEnd"
              @order="onOrderChange(column.key, $event)"
            />
          </div>
        </div>
      </div>
      <div class="contacts-table-body">
        <template v-if="!isLoading && !isEmpty">
          <div
            v-for="contact in items"
            :key="contact.contactId"
            class="row contact-row"
            :class="{
              'selected': selected.includes(contact.contactId)
            }"
            @click="$emit('view', contact.contactId)"
          >
            <div
              class="header-cell select-action"
              @click.stop
            >
              <or-checkbox
                :model-value="selected.includes(contact.contactId)"
                @update:modelValue="handleSelect(contact.contactId)"
              />
            </div>
            <div
              v-for="column in orderedColumns"
              :key="column.key"
              class="cell"
              :class="{ resizing: resizingColumnKey === column.key }"
              :style="dataCellCss[column.key]"
            >
              <contacts-table-data-cell
                :type="column.typeOf"
                :contact="contact"
                :column-key="column.key"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <template v-if="isLoading">
      <div class="loading">
        <or-loader />
      </div>
    </template>
    <template v-else-if="isEmpty">
      <div
        class="empty"
        data-test="table-empty"
      >
        {{ emptyTableText }}
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { OrLoaderV3 as OrLoader } from '@onereach/ui-components';
import { OrCheckbox } from '@onereach/ui-components-legacy';

import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { defineComponent, PropType } from 'vue';

import { ContactBooksOrderModes, SchemaItemTypes } from '@/constants';
import { useContactsV1 as useContacts } from '@/stores';
import { Contact, Schema, SchemaItemExtended } from '@/types';
import { ContactsTableColumnsStoredValue } from '@/types/storages';
import { useContactsTableStoredValue } from '@/composables';
import { convertSchemaToOrderedArray, immutableSplice, getDefaultContactsTableStoredValue } from '@/utils';

import ContactsTableColumnHeaderCell from './ContactsTableColumnHeaderCell.vue';
import ContactsTableDataCell from './ContactsTableDataCell';


const typesToOrder = [
  SchemaItemTypes.STRING,
  SchemaItemTypes.MD,
  SchemaItemTypes.INTEGER,
  SchemaItemTypes.DOUBLE,
  SchemaItemTypes.DATE,
  SchemaItemTypes.ARRAY_OF_STRINGS,
  undefined,
  null,
];

export default defineComponent({
  name: 'ContactsTable',
  components: {
    OrLoader,
    OrCheckbox,
    ContactsTableDataCell,
    ContactsTableColumnHeaderCell,
  },
  props: {
    bookName: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<Contact[]>,
      required: false,
      default: () => [],
    },
    schema: {
      type: Object as PropType<Schema>,
      required: true,
    },
    selected: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    columnToOrderBy: {
      type: String,
      required: false,
      default: 'created',
    },
    orderMode: {
      type: String as PropType<ContactBooksOrderModes>,
      required: false,
      default: ContactBooksOrderModes.DESC,
    },
    isSearching: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['select', 'view', 'order'],
  setup(props) {
    const contactsTableStoredValue = useContactsTableStoredValue(props.bookName, props.schema);

    const { isLoading } = storeToRefs(useContacts());

    return {
      typesToOrder,
      contactsTableStoredValue,
      isLoading,
    };
  },
  data() {
    return {
      resizingColumnKey: null as string | null,
    };
  },
  computed: {
    storedColumnsInfo(): ContactsTableColumnsStoredValue {
      return this.contactsTableStoredValue.columns;
    },
    dataCellCss() {
      return _.mapValues(this.storedColumnsInfo, ({ width }) => ({
        width: width + 'px',
      }));
    },
    isEmpty(): boolean {
      return this.items.length === 0;
    },
    orderedColumns(): SchemaItemExtended[] {
      return convertSchemaToOrderedArray(this.schema, true)
        .filter(({ key }) => this.storedColumnsInfo[key]?.isShown);
    },
    allItemsIds(): string[] {
      return this.items.map(({ contactId }) => contactId);
    },
    isAnySelected(): boolean {
      return this.selected.length > 0;
    },
    isAllSelected(): boolean {
      return !this.isEmpty && _.difference(this.allItemsIds, this.selected).length === 0;
    },
    mainCheckboxState() {
      if (this.isAllSelected) {
        return {
          icon: 'done',
        };
      }
      if (this.isAnySelected) {
        return {
          icon: 'add',
        };
      }
      return {
        icon: '',
      };
    },
    isAnyResizing(): boolean {
      return this.resizingColumnKey !== null;
    },
    emptyTableText(): string {
      return this.isSearching ? this.$t('contacts.table.noMatchingRecords') : this.$t('contacts.table.empty');
    },
  },
  watch: {
    schema(val) {
      const defaultStoredValue = getDefaultContactsTableStoredValue(val);
      _.defaults(this.contactsTableStoredValue.columns, defaultStoredValue.columns);
    },
  },
  methods: {
    handleSelectAll(): void {
      this.$emit('select', this.isAllSelected ? [] : _.union(this.allItemsIds, this.selected));
    },
    handleSelect(contactId: string): void {
      const index = this.selected.indexOf(contactId);
      if (index > -1) {
        this.$emit('select', immutableSplice(this.selected, index, 1));
      } else {
        this.$emit('select', [...this.selected, contactId]);
      }
    },
    onColumnHeaderWidthChangeStart(columnKey: string): void {
      this.resizingColumnKey = columnKey;
    },
    onColumnHeaderWidthChange(columnKey: string, newWidth: number): void {
      this.storedColumnsInfo[columnKey].width = newWidth;
    },
    onColumnHeaderWidthChangeEnd(): void {
      this.resizingColumnKey = null;
    },
    onOrderChange(columnKey: string, newOrderMode: ContactBooksOrderModes): void {
      this.$emit('order', columnKey, newOrderMode);
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.contacts-table-container {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: $c-neutral-0;
  border: 1px solid $c-neutral-2;
  border-radius: $s-2;

  &.full-height {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .contacts-table {
    display: table;
    width: auto;
    background-color: $c-neutral-0;

    .contacts-table-head {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .row {
      display: flex;
      width: auto;
      clear: both;

      .cell,
      .header-cell {
        display: inline-block;

        & > ::v-deep(*) {
          overflow: hidden;
        }
      }

      &.head-row {
        height: 34px;
        font-size: $fs-0;
        font-weight: bold;
        background-color: $c-neutral-2;

        .header-cell {
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: $c-neutral-2;
          }

          &:last-child ::v-deep(.resize-bar) {
            right: -1px;
            width: 3px;
          }

          padding: 0 0 0 16px;
          text-align: start;
          white-space: nowrap;
          border-right: 1px solid $c-neutral-3;

          &.action-select {
            flex: 1;
            width: 56px;
            padding: 5px 20px;
            text-align: center;

            .or-checkbox {
              vertical-align: middle;
            }
          }
        }
      }

      &.contact-row {
        height: 56px;
        font-size: px-to-rem(14);

        &:not(:last-child) {
          border-bottom: 1px solid $c-neutral-2;
        }

        & > * {
          vertical-align: middle;
        }

        .header-cell {
          position: sticky;
          left: 0;
          z-index: 1;
          background-color: $c-neutral-0;
          border-right: 1px solid $c-neutral-2;

          &.select-action {
            flex: 1;
            width: 56px;
            padding: 20px;
            text-align: center;
          }
        }

        .cell {
          white-space: nowrap;
          border-right: 1px solid $c-neutral-2;

          & > * {
            padding: 18px 0 18px 16px;
          }

          & :deep(*) {
            overflow: hidden;
            text-overflow: ellipsis;
          }


          &.resizing {
            border-right: 1px solid $c-primary;
          }
        }

        &:hover,
        &:hover > * {
          cursor: pointer;
          @apply hover:bg-primary-opacity-0-08 dark:hover:bg-primary-opacity-0-08-dark;
          // background-color: $c-primary-lighten-45;
        }

        &.selected,
        &.selected > * {
          background-color: $c-primary-lighten-45;
        }
      }
    }
  }

  .empty,
  .loading {
    position: sticky;
    left: 0;
    padding: $s-3;
    font-size: $fs-1;
    text-align: center;
    background-color: $c-neutral-0;
  }

  .loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
</style>
