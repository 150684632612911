import _ from 'lodash';
import { defineStore } from 'pinia';

import { ContactsApi, ContactsSettingsApi, HitlApi } from '@/api';
import { MigrationsStatus } from '@/constants';
import { TranslatableError } from '@/errors';
import {
  AddBookDTO,
  ApproveSharingDTO,
  Book,
  DeleteBookDTO,
  GetBooksDTO,
  GroupMapping,
  InitTrigger,
  Schema,
  SchemaItemsPropertiesNames,
  SchemaPresets,
  ShareCrossAccountBookDTO,
  StopSharingBookDTO,
  UpdateBookDTO,
} from '@/types';
import { getStoreIdWithoutVersion } from '@/utils';

export const useContactsBooksStore = defineStore('contactBooksV1', {
  state: () => ({
    isInstalled: true,
    migrationsStatus: MigrationsStatus.UNDEFINED,
    total: null as number | null,
    items: [] as Book[],
    schemaPresets: {} as SchemaPresets,
    triggers: [] as InitTrigger[],
  }),
  getters: {
    getBookByName: (state) => {
      return (name: string) => {
        return _.find<Book>(state.items, { name });
      };
    },
    getAccessPermissionsByBookName: (state) => {
      return (name: string) => {
        return _.find<Book>(state.items, { name })?.accessPermissions;
      };
    },
    groupMapping: ({ schemaPresets }): GroupMapping => {
      return _.reduce(schemaPresets, (acc, item, key) => {
        return {
          ...acc,
          [key]: Object.keys(item.fields),
        };
      }, {});
    },
    defaultSchema: ({ schemaPresets }): Schema => {
      return _.reduce(schemaPresets, (acc, item) => {
        return {
          ...acc,
          ...item.fields,
        };
      }, {});
    },
    requiredFields(): string[] {
      return Object.keys(this.defaultSchema);
    },
    requiredFieldsProperties(): SchemaItemsPropertiesNames {
      return _.omitBy(_.mapValues(this.defaultSchema as Schema, item => _.keys(item.properties)), _.isEmpty);
    },
  },
  actions: {
    async checkIfMigrationsNeeded(): Promise<void> {
      try {
        const { checkContactsInstalled } = await ContactsApi.checkContactsInstalled();
        this.isInstalled = checkContactsInstalled;
      } catch (e) {
        throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.checkContactsInstalled`, e);
      }

      if (this.isInstalled) {
        const { checkMigrationsNeeded } = await ContactsApi.checkIfMigrationsNeeded();
        this.migrationsStatus = checkMigrationsNeeded ? MigrationsStatus.IN_PENDING : MigrationsStatus.RESOLVED;
      } else {
        this.migrationsStatus = MigrationsStatus.IN_PENDING;
      }
    },
    async migrateContactsTables(): Promise<void> {
      if (!this.isInstalled) {
        try {
          await ContactsSettingsApi.createContactsTables();
          this.isInstalled = true;
        } catch (e) {
          throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.createContactsTables`, e);
        }
      }

      await ContactsSettingsApi.runContactsServiceTablesMigration();
      this.migrationsStatus = MigrationsStatus.RESOLVED;
      await this.getContactBooks();
    },
    async getContactBooks(): Promise<void> {
      const resp = await ContactsSettingsApi.getContactBooksList({
        projection: [],
        v: 2,
        includeCount: true,
      }) as GetBooksDTO;
      this.total = resp.total;
      this.items = resp.data;
    },
    async addContactBook(payload: AddBookDTO): Promise<void> {
      try {
        await ContactsSettingsApi.createContactBook({
          ...payload,
          schema: payload.schema,
          source: {
            'tableName': payload.tableName,
          },
        });
      } catch (e) {
        // eslint-disable-next-line
        if ('cause' in (e as any) && _.isFunction((e as any).cause)) {
          // eslint-disable-next-line
          const cause = (e as any).cause();
          const errorMessage = _.get(cause, 'response.data.error') as string | unknown;
          if (typeof errorMessage === 'string') {
            if (errorMessage.includes('book with this name already exist'))
              // eslint-disable-next-line max-len
              throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.bookWithThisNameAlreadyExists`, e);
          }
        }
        throw e;
      }
      await this.getContactBooks();
    },
    async deleteContactBook({ name }: DeleteBookDTO): Promise<void> {
      await ContactsSettingsApi.deleteContactBook({
        name,
      });
      await this.getContactBooks();
    },
    async getContactBook(name: string): Promise<void> {
      const data = await ContactsSettingsApi.getContactBook({
        name,
        includeCount: true,
      }) as Book;
      const index = _.findIndex(this.items, { name: data.name });
      if (index > -1) {
        this.items[index] = data;
      } else {
        this.items.push(data);
      }
    },
    async updateContactBook(book: UpdateBookDTO): Promise<void> {
      await ContactsSettingsApi.updateContactBook(book);
      await this.getContactBook(book.name);
    },
    async getBookFields(): Promise<void> {
      this.schemaPresets = await ContactsSettingsApi.getDefaultFields() as SchemaPresets;
    },
    async getTriggers(): Promise<void> {
      this.triggers = await HitlApi.sessions.getSessionInitTriggers() as InitTrigger[];
    },
    async shareCrossAccountContactBook(dto: ShareCrossAccountBookDTO): Promise<void> {
      try {
        await ContactsSettingsApi.shareCrossAccountContactBook(dto);
      } catch (e) {
        // eslint-disable-next-line
        if ('cause' in (e as any) && _.isFunction((e as any).cause)) {
          // eslint-disable-next-line
          const cause = (e as any).cause();
          const errorMessage = _.get(cause, 'response.data.error') as string | unknown;
          if (typeof errorMessage === 'string') {
            if (errorMessage.includes('index_not_found_exception'))
              // eslint-disable-next-line max-len
              throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.accountNotFound`, e);
            if (errorMessage.includes('book with this name already exist'))
              // eslint-disable-next-line max-len
              throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.bookWithThisNameAlreadyExists`, e);
          }
        }
        throw e;
      } finally {
        await this.getContactBook(dto.name);
      }
    },
    async approveSharingUsersList(dto: ApproveSharingDTO): Promise<void> {
      await ContactsSettingsApi.approveSharingUsersList(dto);
      await this.getContactBook(dto.name);
    },
    async stopSharingContactBook(dto: StopSharingBookDTO): Promise<void> {
      await ContactsSettingsApi.stopSharingContactBook(dto);
      await this.getContactBook(dto.name);
    },
    async getInitialData() {
      await Promise.all([
        this.getTriggers(),
        this.getBookFields(),
      ]);
    },
  },
});

export default useContactsBooksStore;
