<template>
  <div class="header-cell-content">
    <div class="text-content">
      <or-button
        v-if="canBeOrdered"
        :label="value"
        size="small"
        type="text"
        :content="value"
        :icon="orderMode !== null ? icon : ''"
        icon-position="right"
        color="neutral-6"
        is-text
        @click="changeOrder"
      />
      <span
        v-else
        class="text"
      >{{ value }}
      </span>
    </div>
    <div
      ref="resizeBarRef"
      class="resize-bar"
      :class="{ resizing: isResizing, 'can-be-resized': canBeResizedNow }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useDraggable } from '@vueuse/core';
import { ContactBooksOrderModes } from '@/constants';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';

export default defineComponent({
  name: 'ContactsTableColumnHeaderCell',
  components: {
    OrButton,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    canBeResizedNow: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    canBeOrdered: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    orderMode: {
      type: String as PropType<ContactBooksOrderModes | null>,
      required: false,
      default: () => null,
    },
  },
  emits: ['barDragStart', 'widthChange', 'barDragEnd', 'order'],
  setup(props, { emit }) {
    const resizeBarRef = ref<HTMLElement | null>(null);

    const minWidth = 17; // 16px padding + 1px border
    let startPositionX = 0;
    let isResizing = ref(false);

    useDraggable(resizeBarRef, {
      onStart: (position, event) => {
        emit('barDragStart', props.columnKey);
        document.body.style.cursor = 'col-resize';
        document.body.style.userSelect = 'none';
        startPositionX = event.x;
        isResizing.value = true;
      },
      onMove: (position => {
        const delta = position.x - startPositionX;
        const newWidth = props.width + delta;
        const finalWidth = newWidth > minWidth ? newWidth : minWidth;
        emit('widthChange', props.columnKey, finalWidth, delta);
        startPositionX = position.x;
      }),
      onEnd: () => {
        emit('barDragEnd', props.columnKey);
        document.body.style.cursor = '';
        document.body.style.userSelect = '';
        isResizing.value = false;
      },
    });

    return {
      resizeBarRef,
      isResizing,
    };
  },
  computed: {
    icon(): string {
      switch (this.orderMode) {
        case ContactBooksOrderModes.ASC:
          return 'arrow_downward';
        case ContactBooksOrderModes.DESC:
          return 'arrow_upward';
        default:
          return '';
      }
    },
  },
  methods: {
    changeOrder(): void {
      switch (this.orderMode) {
        case ContactBooksOrderModes.ASC:
          this.$emit('order', ContactBooksOrderModes.DESC);
          break;
        case ContactBooksOrderModes.DESC:
          this.$emit('order', ContactBooksOrderModes.ASC);
          break;
        default:
          this.$emit('order', ContactBooksOrderModes.DESC);
          break;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@onereach/styles/src/global" as *;

.header-cell-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8px 0;
  overflow: visible !important;
}

.text-content {
  overflow: hidden;

  .text {
    height: auto;
    padding: 0;
    margin-right: $s-2;
    font-size: $fs-0;
    font-weight: bold;
    color: $c-neutral-6;
  }
}

.resize-bar {
  position: absolute;
  top: 0;
  right: -3px;
  width: 5px;
  height: 100%;
  cursor: col-resize;

  &.can-be-resized:hover,
  &.resizing {
    background-color: $c-primary;
  }
}
</style>
