import { HITL } from '@or-sdk/hitl';
import _ from 'lodash';

import config from '@/config';
import { useAuth } from '@/stores';

const instance = new HITL({
  token: () => {
    return `${_.get(useAuth(), 'token')}`;
  },
  hitlUrl: config.HITL_API_URL,
});

export default instance;

