<template>
  <OrDateTimePickerV3
    v-model="localData"
    class="!w-full"
    :label="fieldSchema.label"
    :placeholder="$t('contacts.contactsForm.enterFieldValue', { name: fieldSchema.label })"
  />
</template>
<script lang="ts">
import { OrDateTimePickerV3 } from '@onereach/ui-components';
import { defineComponent, PropType } from 'vue';

import { SchemaItem } from '@/types';
import { dateToLocalISO } from '@/utils';

export default defineComponent({
  name: 'ContactFormFieldDate',
  components: {
    OrDateTimePickerV3,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: [String, Date],
      required: false,
      default: null,
    },
  },
  emits: ['update:data'],
  data() {
    return {
      localData: this.data ? new Date(this.data) : null,
    };
  },
  watch: {
    data(val) {
      const newDate = new Date(val);
      if (+(this.localData ?? 0) != +newDate) this.localData = newDate;
    },
    localData(val) {
      if (+val != +(new Date(this.data))) this.$emit('update:data', val ? dateToLocalISO(val) : null);
    },
  },

});
</script>
