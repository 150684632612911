<template>
  <div>{{ value }}</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Contact, StringPropertyType } from '@/types';

export default defineComponent({
  name: 'ContactsTableDataCellString',
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    columnKey: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    value(): StringPropertyType {
      return (this.contact[this.columnKey] ?? '') as StringPropertyType;
    },
  },
});
</script>
