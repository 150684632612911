<template>
  <div class="contact-view-field-array-of-objects flex flex-col gap-md pb-md pt-sm md:pt-sm+">
    <div
      v-for="(object, index) in convertedData"
      :key="index"
      class="contact-view-field-object-item flex flex-row flex-wrap items-center"
      data-test="contact-view-field-object-item"
    >
      <div class="text text-ellipsis overflow-hidden mr-md">
        {{ object.val }}
      </div>
      <div class="chips-container max-w-full flex flex-row flex-wrap gap-xs">
        <or-tag
          v-for="type of object.type"
          :key="type"
        >
          {{ type }}
        </or-tag>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { OrTagV3 as OrTag } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';

import { ObjectValue, SchemaItem } from '@/types';


type ConvertedObject = {
  val: string;
  type: string[];
};

export default defineComponent({
  name: 'ContactsViewFieldArrayOfObjects',
  components: {
    OrTag,
  },
  props: {
    fieldSchema: {
      type: Object as PropType<SchemaItem>,
      required: true,
    },
    data: {
      type: Array as PropType<ObjectValue[]>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    convertedData(): ConvertedObject[] {
      return this.data?.map(objValue => ({
        val: _.join(_.toPairs(objValue).filter(([key]) => key !== 'type')
          .map(([, value]) => value).filter(value => !_.isEmpty(value)), ', '),
        type: (objValue.type as string[] | undefined) ?? [],
      })) ?? [];
    },
  },
});
</script>
