import { IdentifierValue } from '@/types';

function getDefaultIdentifier(): IdentifierValue {
  return {
    val: '',
    type: [],
  };
}

export default getDefaultIdentifier;
