import _ from 'lodash';
import { Schema, SchemaItem, SchemaItemExtended } from '@/types';
import getAllCompositeColumns from './getAllCompositeColumns';

function convertSchemaToOrderedArray(schema: Schema, withCompositeColumns = false): SchemaItemExtended[] {
  const schemaItemsWithKey = _.map(schema, (item: SchemaItem, key) => ({
    ...item,
    key: key,
  }));

  const itemsWithKey = withCompositeColumns ?
    createArrayWithCompositeColumns() :
    schemaItemsWithKey;

  return _.map(_.orderBy(itemsWithKey, 'order'), (item, index) => ({
    ...item,
    isChanged: false,
    order: index,
  }));

  function createArrayWithCompositeColumns(): typeof schemaItemsWithKey {
    const compositeColumns = getAllCompositeColumns();
    const columnsToHide = compositeColumns.flatMap(({ parts }) => parts);

    const realColumns = schemaItemsWithKey.filter(item => !columnsToHide.includes(item.key));
    const virtualColumns = compositeColumns.map(column => ({
      visibility: false,
      label: column.label,
      order: _.min(column.parts.map(key => schemaItemsWithKey
        .find(item => item.key === key)?.order ?? schemaItemsWithKey.length)),
      key: column.key,
    } as SchemaItemExtended & { key: string; }));

    return _.concat(realColumns, virtualColumns);
  }
}

export default convertSchemaToOrderedArray;
