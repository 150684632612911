import _ from 'lodash';
import { defineStore } from 'pinia';
import { v4 } from 'uuid';

import ContactsApi from '@/api/ContactsApi';
import {
  Contact,
  ContactDTO,
  ContactsCollection,
  DeleteContactsDTO,
  GetContactsProjectionDTO,
  MergeContactsDTO,
  SearchContactsDTO,
} from '@/types';

const useContactsStore = defineStore('contactsV1', {
  state: () => ({
    collections: {} as { [key: string]: ContactsCollection;},
    contacts: {} as { [id: string]: Contact; },
  }),

  getters: {
    getContactsByBookName: (state) => {
      return (bookName: string) => {
        return state.collections[bookName]
          ? state.collections[bookName]
          : {} as typeof state.collections[string];
      };
    },

    getContactById: (state) => (contactId: string): Contact => state.contacts[contactId],
  },

  actions: {
    async getContacts(payload: SearchContactsDTO): Promise<void> {
      const {
        bookName,
        size = 25,
        from = 0,
        searchStr: searchPhrase,
        columnToOrderBy: columnToOrderBy,
        orderMode: orderMode,
        filterId,
      } = payload;


      const { data, total } = await ContactsApi.searchContacts({
        bookName,
        searchPhrase,
        size,
        v: 2,
        from,
        sort: `${columnToOrderBy}:${orderMode}`,
        filterId,
      });
      this.collections = {
        ...this.collections,
        [bookName]: {
          items: data,
          total,
        },
      };
      (data as Contact[]).forEach(contact => this.contacts[contact.contactId] = contact);
    },

    //TODO: searchContacts returns 500 for big tables, must be fixed in SDK
    /*
    async getAllContactsProjection<T extends(keyof Contact)[]>(
      payload: GetContactsProjectionDTO<T>
    ): Promise<Pick<Contact, T[number]>[]> {
      const data = await ContactsApi.searchContacts({
        ...payload,
        v: 2,
      });

      const typedData = data as (Pick<Contact, T[number]> & { contactId?: string; })[];
      if (typedData.length > 0 && 'contactId' in typedData[0]) {
        typedData.forEach(contact => {
          const id = contact.contactId as string;
          const prevContact = this.contacts[id];
          this.contacts[id] = {
            ...prevContact,
            ...contact,
          };
        });
      }

      return typedData;
    },
     */
    //TODO: remove after fix in SDK
    async getAllContactsProjection<T extends(keyof Contact)[]>(
      payload: GetContactsProjectionDTO<T>,
      pageSize = 100,
      maxSameTimeRequestsCount = 10
    ): Promise<Pick<Contact, T[number]>[]> {
      const { total } = await ContactsApi.searchContacts({
        bookName: payload.bookName,
        size: 0,
        from: 0,
        v: 2,
        projection: [],
      });

      type DataType = (Pick<Contact, T[number]> & { contactId?: string; })[];
      type SearchReturnType = {
        data: DataType;
        total: number;
      };

      const results = [] as SearchReturnType[][];

      const requestsPayloads = _.range(0, total, pageSize).map(from => ({
        ...payload,
        v: 2,
        from,
        size: pageSize,
      }));

      const chunkedPayloads = _.chunk(requestsPayloads, maxSameTimeRequestsCount);
      for (const payloadChunk of chunkedPayloads) {
        const promisesChunk = payloadChunk.map(requestPayload =>
          ContactsApi.searchContacts(requestPayload) as Promise<SearchReturnType>);
        results.push(await Promise.all(promisesChunk));
      }

      const flattenResults = _.flattenDeep(results);
      const typedData = _.uniq(_.flatMap(flattenResults, result => result.data));

      if (typedData.length > 0 && 'contactId' in typedData[0]) {
        typedData.forEach(contact => {
          const id = contact.contactId as string;
          const prevContact = this.contacts[id];
          this.contacts[id] = {
            ...prevContact,
            ...contact,
          };
        });
      }

      return typedData;
    },

    async updateContact({ contactData, bookName }: ContactDTO): Promise<void> {
      await ContactsApi.updateContact({
        contactData,
        bookName,
      });
    },

    async createContact({ contactData, bookName }: ContactDTO): Promise<void> {
      // eslint-disable-next-line
      const { contactId, ...contactDataToSend } = contactData;
      await ContactsApi.createContact({
        contactData: {
          contactId: v4(), // TODO: GENERATE ID ON BACKEND
          ...contactDataToSend,
        },
        bookName,
      });
    },

    async deleteContacts({ contactIds, bookName }: DeleteContactsDTO): Promise<void> {
      await ContactsApi.deleteContact({
        contactIds,
        bookName,
      });
      contactIds.forEach(id => delete this.contacts[id]);
    },

    async deleteAllContacts(bookName: string): Promise<void> {
      //TODO: ADD DELETING ALL CONTACTS TO SDK
      const recordsCount = (await ContactsApi.getContactRecordsCount({
        bookName,
      })).count;

      const contacts = await ContactsApi.getContactsList({
        bookName,
        from: 0,
        size: recordsCount,
        projection: ['contactId'],
      });

      const contactIds = (contacts.data as { contactId: string; }[]).map(({ contactId }) => contactId);

      await ContactsApi.deleteContact({
        contactIds,
        bookName,
      });
      contactIds.forEach(id => delete this.contacts[id]);
    },

    async mergeContacts({ toContact, fromContacts, bookName }: MergeContactsDTO): Promise<void> {
      await ContactsApi.mergeContacts({
        toContact,
        fromContacts,
        bookName,
      });
    },
  },
});

export default useContactsStore;
