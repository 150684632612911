import i18n from '@/i18n';

function useI18n() {
  return {
    t: i18n.global.t,
    i18n,
  };
}

export default useI18n;
